.wrapper {
  width: 400px;
  max-width: 100%;
  margin: 0 auto;

  font-size: 12px;

  @media only screen and (min-width: 768px) {
    font-size: 14px;
    width: 600px;
  }

  @media only screen and (min-width: 1366px) {
    font-size: 16px;
  }
}

.row {
  @media only screen and (min-width: 768px) {
    display: flex;
    align-items: flex-start;
    justify-content: space-between;

    > * {
      width: 48%;
    }
  }
}

.phone {
  margin-left: auto;
  width: auto;
  margin-bottom: 20px;
}

.inputGroup {
  display: flex;
  flex-direction: column;
  margin-bottom: 20px;

  input:not([type='checkbox']), select {
    height: 40px;
    line-height: 40px;
    padding: 0 10px;
    border-radius: 5px;
    border-width: 1px;
  }

  input[type='checkbox'] {
    margin-right: 5px;
  }

  label {
    font-size: 90%;
    display: flex;
    align-items: center;
  }

  .inputError {
    color: red;
    font-size: 80%;
    margin-top: 3px;
  }
}

.radioGroup {

}

.beforeSubmit {
  text-align: center;
  margin-bottom: 15px;
}

.submitBtn {
  display: block;
  background-color: var(--blue);
  color: #fff;
  width: 100%;
  height: 40px;
  line-height: 40px;
  border-radius: 5px;

  &:hover:not(:disabled) {
    background-color: var(--purple);
  }

  &:disabled {
    opacity: 0.5;
  }
}

.title {
  text-align: center;
  font-weight: 500;
  font-size: 20px;
  margin-bottom: 10px;

  @media only screen and (min-width: 768px) {
    font-size: 24px;
  }

  @media only screen and (min-width: 992px) {
    font-size: 28px;
  }

  @media only screen and (min-width: 1366px) {
    font-size: 36px;
  }
}

.subtitle {
  text-align: center;
  font-weight: 400;
  font-size: 16px;
  max-width: 400px;
  margin: 0 auto 30px;
}

.selectLocale {
  margin-bottom: 20px;

  p {
    margin-top: 0;
    font-size: 14px;
    margin-bottom: 10px;
  }

  label {
    margin-right: 15px;
    display: inline-flex;
    align-items: center;
  }

  input {
    margin-right: 3px;
  }
}
