.wrapper {
  display: flex;
  flex-wrap: wrap;
  flex-grow: 1;
}

.slot {
  height: 70px;
  width: 70px;
  margin-right: 15px;
  margin-bottom: 15px;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  display: flex;
  align-items: center;
  justify-content: center;
  border: 1px solid rgba(0, 0, 0, 0.4);
  cursor: pointer;

  &.active {
    border-color: red;
    box-shadow: 0 0 3px 0 rgba(red, 0.8);
  }

  &:hover {
    box-shadow: 0 0 3px 0 rgba(red, 0.8);
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}