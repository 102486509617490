.wrapper {
  display: flex;
  margin-bottom: 30px;
  flex-wrap: wrap;
}

.productName {
  width: 100%;
  margin-bottom: 20px;

  @media only screen and (min-width: 1366px) {
    font-size: 16px;
    width: 200px;
    margin-right: 50px;
  }
}

.planColumn {
  margin-right: 20px;

  @media only screen and (min-width: 992px) {
    width: 300px;
    margin-right: 50px;
  }
}

.fieldGroup {
  display: flex;
  justify-content: flex-end;
  margin-bottom: 10px;
  align-items: center;

  label {
    display: inline-block;
    margin-right: 10px;
  }

  select {
    width: 100px;
    padding: 5px 5px;
  }
  
  input[type='number'] {
    width: 100px;
    padding: 5px 10px;
  }

  input[type='checkbox'] {
    margin-right: 5px;
  }
}