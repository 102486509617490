.wrapper {
  margin-right: 35px;
  align-self: stretch;
}

.innerWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
  align-items: center;

  &.upTo3 {
    width: 580px;
  }

  &.upTo4 {
    width: 420px;
  }

  &.upTo6 {
    width: 580px;
  }
}

.itemWrapper {
  user-select: none;
}

.box {
  width: 180px;
  height: 180px;

  display: flex;
  align-items: center;
  justify-content: center;
}
