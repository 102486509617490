.slotsWrapperOuter {
  position: absolute;
  top: 10%;
  left: 50%;
  transform: translateX(-50%);

  width: 80vmin;
  height: 14vmin;
}

.slotsWrapperInner {
  display: flex;
}

.slots {
  width: 100%;
  display: flex;
  justify-content: center;
  padding: 1vmin 0;
}

.slot {
  width: 6vmin;
  display: flex;
  align-items: flex-end;
  justify-content: center;
  position: relative;
  line-height: 1;
  padding-bottom: 0.5vmin;

  &:not(.fixed)::after {
    content: '';
    height: 0.3vmin;
    background-color: #000;
    position: absolute;
    bottom: 0;
    width: 50%;
  }

  &.active::after {
    opacity: 0;
    background-color: var(--blue);
    animation: blink 1s infinite;
  }

  &:not(.fixed)::before {
    content: attr(data-letter);
    height: 100%;
    width: 100%;
    display: flex;
    align-items: center;
    justify-content: center;
    position: absolute;
    bottom: 100%;
    // background-color: rgba(0, 0, 0, 0.4);
    padding: 0.5vmin;
    border-radius: 20%;
    bottom: 0;
    color: #fff;

    opacity: 0;
    visibility: hidden;
    transition: transform 0.2s ease;
  }

  &.correct::before {
    background-color: rgba(limegreen, 0.8);
  }

  &.incorrect::before {
    background-color: rgba(red, 0.8);
  }
}

.correctAnswerVisible .slot::before {
  opacity: 1;
  visibility: visible;
  transform: translateY(-105%);
}

@keyframes blink {
  50% {
    opacity: 1;
  }
}

.wordsBoxOuter {
  position: absolute;
  right: 0;
  min-width: 22vmin;
  font-size: 2vmin;
  font-family: 'Roboto', sans-serif;
  top: 49vh;
  transform: translateY(-37vmin);
}

.wordsBoxInner {
  overflow-x: auto;
}

.wordsList {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 0.2vmin 1vmin;
    user-select: none;

    &.resolved {
      text-decoration: line-through;
      background-color: limegreen;
    }
  }
}
