.writing-assistant-editor {
  width: 100%;
  height: 100%;
  overflow: hidden;

  display: flex;
  flex-direction: column;

  .ql-container.ql-snow {
    border: none;
  }

  .ql-editor {
    font-size: 18px;
    font-family: Arial;
  }

  .ql-picker-options {
    max-height: 150px;
    overflow-y: auto;
  }

  .ql-snow {
    .ql-picker.ql-font {
      width: 140px;
    }
    .ql-picker.ql-size {
      width: 50px;
    }
  }

  .ql-toolbar.ql-snow {
    display: flex;
    align-items: center;
    justify-content: space-between;
    flex-shrink: 0;

    border: none;
    border-bottom: 5px solid #ddecf6;
    
    &::after { // removes the whitespace that breaks flex
      display: none;
    }

    .ql-formats {
      margin-right: 8px;
    }

    .ql-writing-feedback button {
      width: max-content !important;
      height: auto !important;

      img, span {
        vertical-align: middle;
        display: inline-block;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      }

      img {
        padding-right: 5px;
      }

      &:disabled img { 
        pointer-events: none !important;
      }

      &:disabled {
        cursor: default;
        opacity: 0.8;
        filter: grayscale(1);
        pointer-events: none;
      }
    }

    .ql-formats--images {
      position: relative;
      padding-left: 8px;
      margin-right: 0;

      button {
        width: 50px;
        height: 50px;
      }

      &::before {
        position: absolute;
        content: "";
        width: 5px;
        top: -8px;
        bottom: -8px;
        left: 0;
        background-color: #ddecf6;
      }
    }
  }

  img {
    pointer-events: visible; // Need for quill-image-resize-module
  }

  .wordsWrapper {
    padding: 0 10px;
    border-bottom: 5px solid #ddecf6;
    flex-shrink: 0;
    padding-top: 3px;
    padding-bottom: 3px;
    font-size: 18px;

    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;

    span {
      cursor: pointer;
      margin: 3px;
      padding: 0 3px;
      border: 2px dashed #c77b6c;
      border-radius: 10px;
      transition: all 0.2s ease;

      &:hover {
        background-color: #c77b6c;
        color: #fff;
      }
    }
  }

  .imagesWrapper {
    // padding: 0 5px;
    display: flex;
    align-items: center;
    margin: 0 -2px;
    border-bottom: 5px solid #ddecf6;
    flex-shrink: 0;

    span {
      height: 100px;
      cursor: pointer;
      margin: 0 2px;
      width: calc(100% / 8);

      background-position: center;
      background-size: cover;
      background-repeat: no-repeat;

      transition: transform 0.2s ease;

      &:hover {
        transform: scale(0.9);
      }
    }

    img {
      max-height: 100%;
      width: auto;
      display: block;
    }
  }

  .editor {
    flex-grow: 1;
    overflow: auto;
  }

  .reading-area {
    overflow: auto;
    flex-grow: 0;
    flex-shrink: 0;

    border-bottom: 5px solid #ddecf6;

    height: 300px;

    &.small {
      height: 200px;
    }
  }
}
