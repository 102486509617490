$overlay-default-width: 15vmin;
$overlay-border-radius: 3vmin;
$overlay-width: calc(#{$overlay-default-width} + #{$overlay-border-radius});

@keyframes blink-text {
  0% {
    opacity: 0;
  }
  50% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.currentTurnIndicator {
  color: #008a0b;
  animation: blink-text 1.9s linear infinite;
}

.playerName {
  font-size: clamp(12px, 1.5vw, 16px);
}

.items {
  display: flex;
  flex-direction: column;
}

.wrapper {
  position: absolute;
  left: 99%;
  top: 2vmin;
  margin: 0;
  transform: translate(-99%);
  flex-direction: column;
  display: inline-flex;
  gap: 12px;
  z-index: 10;
}

.outerClass {
  z-index: 1;

  width: #{$overlay-default-width};
  max-width: #{$overlay-default-width};

  display: flex;
}

.innerClass {
  flex-grow: 1;
  display: flex;
  padding: 6.5px;

  > * {
    height: 100%;
    width: 100%;
  }

  small {
    font-size: clamp(8px, 1.5vw, 12px) !important;
    font-family: sans-serif !important;
  }
}
