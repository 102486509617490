.fadeIn {
  opacity: 0;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
}

.fadeOut {
  opacity: 1;
  animation-name: fadeOut;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
