.datePickers {
  display: block;

  > * {
    display: flex;
    align-items: center;
    margin-bottom: 10px;
    justify-content: space-between;
  }

  label {
    margin-right: 5px;
  }
}
