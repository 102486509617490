.header {
    display: flex;
  }
  
  .listsWrapper {
    font-size: 10px;
    display: flex;
    justify-content: space-between;
  
    > * {
      width: 45%;
    }
  }
  
  .wordsList {
    list-style: none;
    margin: 0;
    padding: 0;
  }
  
  .wordField {
    overflow-x: auto;
    height: 25px;
    padding: 5px;
    margin-top: 4px;
    background-color: #fff;
    border: 1px solid #d8d8d8;
    width: 100%;
  }
  