.wrapper {
  height: 100%;
  width: 100%;
  position: relative;
}

.track {
  position: absolute;
  left: 0;
  width: 3.5vmin;
  height: 100%;

  border: 2px solid #b0a9a9;
  border-radius: 1.7vmin;
  width: 3.5vmin;
  background-color: #b0a9a9;
  height: 100%;
  box-sizing: content-box;
}

.thumb {
  position: absolute;
  height: 50%;
  width: 100%;
  background-color: #d1cece;
  cursor: pointer;
  border-radius: 1.5vmin;

  display: flex !important;
  align-items: center;
  justify-content: center;

  img {
    width: 95%;
    max-height: 100%;
  }
}
