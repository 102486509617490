.moving-answers-template {
  width: 100%;
  height: 100%;
  font-family: sans-serif;

  .question-block {
    z-index: 10;
    display: flex;
    align-items: center;
    justify-content: center;
    padding: 1%;
    overflow: hidden;

    background: white;
    height: 18%;
    width: 55%;
    left: 50%;
    transform: translateX(-50%);
    top: 2%;
    position: absolute;
    border: 3px solid black;
    border-radius: 10px;

    &__question {
      color: #000;
      font-size: 14px;
    }
  }
}
