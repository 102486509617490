.wrapper {
  display: flex;
  align-items: center;
  margin: 0;
  flex-grow: 1;
}

.spacer {
  display: flex;
  flex: 10;
}

.row {
  display: flex;
  flex-direction: row;
  gap: 25px;
  align-items: center;
}

.imgDefaultOpen {
  margin-bottom: 20px;
}

.text {
  font-size: 16px;
  margin-left: 30px;
  margin-right: 30px;
}

.test {
  display: flex;
  align-items: center;
  margin-left: auto;
}

.preview-image {
  width: 100%;
}

.addBtnWrapper {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  margin-right: 10px;

  &.disabled  {
    opacity: 0.7;
  }
}

$side: 60px;

.imageAnswerPreview {
  width: $side;
  height: $side;
  background-color: #fff;
  border: 1px solid #000;
  display: flex;
  align-items: center;
  justify-content: center;
  margin-right: 40px;

  img {
    width: $side - 10px;
    height: $side - 10px;
  }
}
