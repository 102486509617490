.table {
  th:first-child {
    min-width: 250px;
    padding-left: 0;
  }

  td:first-child {
    padding-left: 0;
    text-align: left;
  }

  th {
    text-align: left;
    padding: 10px;
  }

  td {
    text-align: center;
    padding: 10px;
  }
}

.title {
  font-size: 20px;
  margin-bottom: 10px;
}
