.wrapper {
  display: flex;
  justify-content: space-between;
  flex-wrap: wrap;
  margin-bottom: 2vmin;
  padding: 1vmin;
}

.buttonWrapper {
  width: 33%;
  margin-bottom: 2vmin;
}

.button {
  position: relative;
  width: 100%;
  display: flex;
  flex-direction: column;
  align-items: center;

  &::after {
    position: absolute;
    top: 5vmin;
    left: 50%;
    content: "\274c";
    font-size: 6vmin;
    color: red;
    transform: translate(-50%, -50%) scale(0);
    transition: all 0.3s ease-out;
    visibility: hidden;
  }

  &.off::after {
    transform: translate(-50%, -50%) scale(1);
    visibility: visible;
  }

  img {
    height: 12vmin;
    margin-bottom: 1vmin;
    transition: transform 0.2s ease-out;
    transform: scale(0.92);
    backface-visibility: hidden;
  }

  &:disabled {
    cursor: default;
    opacity: 0.7;
  }

  &:hover:not(:disabled) img {
    transform: scale(1);
  }

  span {
    font-weight: 500;
    font-size: 2vmin;
  }
}
