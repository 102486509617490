.videoOverlay {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  background-color: #000;
  z-index: 1000;
  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 0;

  &.loaded {
    opacity: 1;
    visibility: visible;
  }
}

.overlayText {
  color: #fff;
  position: absolute;
  z-index: 10;
  top: 3vmin;
  right: 3vmin;
  font-size: 4vmin;
  margin: 0;
  cursor: pointer;
  text-shadow: 0 0 5px #000;
}

.video {
  max-width: 100vw;
  max-height: 100vh;
  cursor: pointer;
}
