.set-goal-calendar {
  font-family: Roboto, sans-serif !important;
  font-size: 2vmin !important;
  border-radius: 0 !important;
  min-height: 100%;

  .react-datepicker__day,
  .react-datepicker__day-name {
    font-size: 1.8vmin;
    width: 3.5vmin;
    line-height: 3vmin;
  }

  .react-datepicker__current-month {
    font-size: 2vmin;
    margin-bottom: 1vmin;
  }

  .react-datepicker__navigation {
    height: 2vmin;
    width: 2vmin;
    border-width: 1vmin;
  }
}
