.modal-wrapper {
  position: fixed;
  left: 0;
  top: 0;
  right: 0;
  height: 0;
  z-index: 100;
}

.modal-overlay {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(#000, .3);
}
