.loginGroup {
  background-color: rgba(#fff, 0.8);
  border-radius: 1.5vw;
  padding: 2vw 2vw 3vw;
  margin-right: 2vw;
  display: flex;
  flex-direction: column;
  min-width: 21vw;

  &.familyLogin {
    padding: 2vw 2vw 2vw;
  }

  // @media (orientation: portrait) and (max-width: 991px) {
  //   margin-right: 0;
  //   margin-bottom: 2vw;
  //   width: 50vw;
  // }

  // @media (orientation: portrait) and (max-width: 565px) {
  //   margin-right: 0;
  //   margin-bottom: 5vw;
  //   width: 100%;
  // }
}

.loginGroupTitle {
  font-weight: 500;
  display: block;
  margin-bottom: 0.5vw;
}

.inputGroup {
  position: relative;

  &:not(:last-child) {
    margin-top: 1.5vw;
    margin-bottom: 2.2vw;

    // @media (orientation: portrait) and (max-width: 565px) {
    //   margin-top: 3vw;
    //   margin-bottom: 4.4vw;
    // }
  }

  input {
    width: 100%;
    border: 1px solid var(--blue);
    background-color: #fff;
    border-radius: 1.3vw;
    padding: 0.7vw 1.5vw 0.8vw;
    font: inherit;
    font-size: 1vw;
    line-height: 1;
    filter: none;

    // @media (orientation: portrait) and (max-width: 565px) {
    //   font-size: 2vw;
    //   padding: 1.4vw 3vw 1.6vw;
    // }

    &:focus {
      outline: none;
      border: 1px solid var(--purple);
    }
  }

  &.errorState input {
    border-color: #cc2020;
  }
}

.error {
  position: absolute;
  top: 1px;
  left: 0;
  right: 0;
  text-align: center;
  font-size: 70%;
  transform: translateY(-100%);
  color: #cc2020;
  z-index: 1;
}
