.blocker {
  position: fixed;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
  z-index: 1400;
  background-color: rgba(0, 0, 0, 0.5);
}

.settingsOuter {
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.settingsInner {
  background-color: #fff;
  width: 90vmin;
  max-width: 800px;
  max-height: 82vmin;
  position: relative;
}

.popup {
  background-color: #fff;
  padding: 1.5vmin;
  display: flex;
  flex-direction: column;
}

.popupViewport {
  overflow-y: auto;
  flex-grow: 1;
}

.logoutWrapper {
  display: flex;
  justify-content: center;
  margin-top: 3vmin;
}
