.wrapper {
  position: absolute;
  right: 0;
  top: calc(50% - 6.5vmin); // 6.5vmin - it's half of global button space (13vmin)
  left: 50%;
  transform: translate(-50%, -50%);

  display: flex;
  justify-content: space-between;

  &.noGuide {
    justify-content: center;
  }
}

.guideInner {
  padding: 0;
}

.textInner {
  padding: 0;
}

