.blocksWrapper {
  top: 26%;
  position: absolute;
  // left: 50%;
  display: flex;
  justify-content: center;
  align-items: flex-start;

  left: 50%;
  transform: translateX(-50%);
}