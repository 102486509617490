.listsWrapper {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;
  flex-grow: 1;
  overflow-y: auto;
  margin-bottom: 2vmin;
}

.radioListWrapper {
  border: 2px solid #972bc9;
  border-radius: 1vmin;
  padding: 1vmin;
  width: 49%;
  margin-bottom: 1.5vmin;
}

.radioListLabel {
  font-weight: 500;
  font-size: 2vmin;
  margin: 0;
  margin-bottom: 1vmin;
}

.radioList {
  margin: 0;
  overflow-y: auto;

  label {
    display: block;
    font-size: 2vmin;
  }

  input {
    display: none;
  }

  span {
    cursor: pointer;
    position: relative;
    display: inline-block;
    padding-left: 2.5vmin;
    // height: 2vmin;
    font-size: 2vmin;
    line-height: 1.6vmin;
    margin-bottom: 0.7vmin;

    &::after {
      content: '';
      visibility: hidden;
      opacity: 0;
      position: absolute;
      height: 0.6vmin;
      width: 0.6vmin;
      left: 0.5vmin;
      top: 0.5vmin;
      border-radius: 50%;
      background-color: #00001d;
      transition: all 0.3s ease;
    }

    &::before {
      content: '';
      position: absolute;
      height: 1.6vmin;
      width: 1.6vmin;
      left: 0;
      top: 0;
      border-radius: 50%;
      background-color: #fff;
      border: 1px solid #00001d;
    }
  }

  input:checked + span::before {
    // background-color: #00001d;
  }
  input:checked + span::after {
    visibility: visible;
    opacity: 1;
  }
}
