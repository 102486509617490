.slot {
  border: 0.5vmin dashed #c77b6c;
  border-radius: 1.5vmin;
  width: 7vw;
  height: 12vh;
  // width: 24vmin;
  // height: 24vmin;
  margin: 0 0.8vmin;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease;
  animation-duration: inherit;
  animation-delay: inherit;
}

.text {
  font-size: 2vmin;
  // word-break: break-all;

  &.textUpTo1 {
    font-size: 10vmin;
  }
  &.textUpTo2 {
    font-size: 4vmin;
  }
  &.textUpTo4 {
    font-size: 2.2vmin;
  }
  &.textUpTo6 {
    font-size: 2.2vmin;
  }
  &.textUpTo10 {
    font-size: 2vmin;
  }
}

.fixedSlot {
  border: none;
}

.boardOuter {
  position: fixed;
  top: 22%;
  left: 50%;
  transform: translateX(-50%);
}

.slotsWrapper {
  max-width: 100%;
  // height: 100%;
  display: flex;
  justify-content: center;
  padding: 0;
  background: none;
}

.slotsInner {
  max-width: 100%;
  display: flex;
  padding: 0;
  background: none;
}

.questionsWrapper {
  display: flex;
  justify-content: center;
}

.questionItemWrapper {
  margin: 0 0.8vmin;
  padding: 0.5vh 0.5vw;
}

.answersWrapper {
  display: block;
  margin-top: 15vh;
  padding: 0;
  background: none;
}

.answersInner {
  display: flex;
  padding: 0;
  background: none;
}

.boardBox {
  width: 7vw;
  height: 12vh;
  margin: 0 0.8vmin;
}
