.label {
  display: block;
  text-align: center;
  width: 100%;
  padding: 10px 30px;
  background-color: #0080ff;
  color: #fff;
  opacity: 0.7;
  transition: opacity .2s ease;
  border-radius: 5px;
  cursor: pointer;

  &:hover {
    opacity: 1;
  }

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  input {
    display: none;
  }
}