.form {
    display: flex;
    flex-direction: column;
    gap: 1.5vmin;

    input {
        width: 100%;
        border: 1px solid var(--blue);
        background-color: #fff;
        font: inherit;
        font-size: 1.95vmin;
        line-height: 1;
        filter: none;
        border-radius: 26px;
        padding: 2vmin 2vmin 2vmin 2vmin;
    
        &:focus {
          outline: none;
          border: 1px solid var(--purple);
        }
    }

    button {
        width: 100%;
        font: inherit;
        color: #fff;
        font-size: 1.95vmin;
        line-height: 1;
        filter: none;
        border-radius: 26px;
        padding: 2vmin 2vmin 2vmin 2vmin;
        text-transform: uppercase;
    }

    button.forgotPassword {
        background-color: var(--purple);
        &:hover {
            box-shadow: 0 0 0.6vw 1px var(--purple);
        }
    }

    button.signIn {
        background-color: var(--blue);
        &:hover {
            box-shadow: 0 0 0.6vw 1px var(--blue);
        }
    }
}

.formError {
    padding: 0;
    margin: 0;
    margin-top: -5px;
    // position: absolute;
    // top: 100%;
    margin-left: 5px;
    color: red;
    font-size: 80%;
  }
