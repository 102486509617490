.rightColumn {
  position: absolute;
  left: 50%;
  width: 22vmin;
  font-size: 1.5vmin;
  font-size: 2vmin;
  top: 49vh;
  transform: translate(27vmin, -37vmin);

  @media only screen and (min-width: 1200px) {
    width: 25vmin;
    transform: translate(37vmin, -37vmin);
  }
}

.titleBox {
  margin-bottom: 1.5vmin;
  text-align: center;

  p {
    margin: 0;
  }
}

.titleBoxInner {
  overflow-x: auto;
}

.wordsList {
  list-style: none;
  padding: 0;
  margin: 0;

  li {
    padding: 0.2vmin 1vmin;
    user-select: none;

    &.resolved {
      text-decoration: line-through;
      background-color: limegreen;
    }
  }
}

.wordsBoxInner {
  overflow-x: auto;
}
