.wrapper {
  display: flex;
  align-items: center;
  align-self: stretch;
  justify-content: space-between;
}

.button {
  cursor: pointer;
  padding: 3px 6px;
  background-color: rgba(255, 255, 255, 0.3);
  border: 1px solid rgba(0, 0, 0, 0.4);
  border-radius: 5px;
  text-align: center;

  &:hover {
    background-color: #0080ff;
    color: #fff;
  }
}

.select {
  display: flex;
  align-items: center;

  label {
    margin-right: 5px;
  }

  select {
    width: 170px;
  }
}

.checkbox {
  display: flex;
  align-items: center;
  cursor: pointer;
  margin-left: 10px;
  user-select: none;

  input {
    margin-right: 5px;
  }
}
