.modal {
  // overflow: hidden;
  position: relative;
  background-color: #fff;
  border-radius: 4px;
  padding: 2px 2px 10px;
  border: 1px solid #a8a8a8;
  width: 100%;
  height: 100%;

  display: flex;
  flex-direction: column;

  > * {
    max-width: 100%;
  }

  &.no-gutters {
    padding-bottom: 0;

    .bar {
      margin-bottom: 0;
    }
  }
}

.bar {
  height: 30px;
  flex-shrink: 0;
  cursor: grab;
  background-color: #ccc;
  border: 1px solid #a8a8a8;
  border-radius: 4px;
  margin-bottom: 10px;
  padding: 0 5px 0 20px;
  position: relative;
  font-weight: 500;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;
}

.content {
  flex-grow: 1;
  overflow-y: auto;
  position: relative;
}

.close-btn {
  font-size: 20px;
  background-color: #ebebeb;
  padding: 0px 5px;
  line-height: 1;
  font-weight: 700;
  color: #888;
  border-radius: 3px;

  position: absolute;
  top: 4px;
  right: 4px;

  &:hover {
    background-color: #a8a8a8;
    color: #fff;
  }
}