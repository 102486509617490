.boxesWrapper {
  position: absolute;
  top: 50%;
  left: 50%;

  display: flex;
  justify-content: center;
  align-items: flex-start;
}

.slot {
  border: 5px dashed #c77b6c;
  border-radius: 15px;
  width: 190px;
  height: 190px;
  margin: 0 8px;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease;
  animation-duration: inherit;
  animation-delay: inherit;
}

.fixedSlot {
  border: none;
}

.text {
  font-size: 15px;
}

.textUpTo1 {
  font-size: 110px;
}
.textUpTo2 {
  font-size: 80px;
}
.textUpTo4 {
  font-size: 40px;
}
.textUpTo6 {
  font-size: 28px;
}
.textUpTo10 {
  font-size: 24px;
}
