.wrapper {
  position: absolute;
  margin: 0;
  left: 0.6vmin;
  top: 2vmin;
  width: 5vmin;

  li {
    box-sizing: border-box;
    border-radius: 50%;
    border: 1px solid #000;
    width: 2vmin;
    height: 2vmin;

    &:not(:last-child) {
      margin-bottom: 0.5vmin;
    }

    &.filled {
      background-color: grey;
    }
  }
}

.inner {
  padding: 0;
}

.list {
  margin: 0;
  padding-left: 0;
  display: flex;
  flex-direction: column;
  list-style: none;
  box-sizing: border-box;
  align-items: center;
}
