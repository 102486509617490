.selectCardsCount {
  position: absolute;
  top: 3vmin;
  left: 4vmin;

  button {
    width: 8vmin;
    margin-right: 2vmin;
    padding: 0.5vmin 1vmin;
    background-color: #ffd480;
    border: 1px solid #000;
    font-size: 2vmin;
    font-weight: 500;
    transition: all 0.3s ease-in-out;
    text-align: center;

    &.active {
      background-color: lighten(#ffd480, 15%);
      transform: rotate(-10deg) translateY(-2px);
      box-shadow: 5px 5px 5px rgba(#000, 0.4);
    }

    &:hover:not(.active) {
      transform: translateY(-2px);
      box-shadow: 5px 5px 5px rgba(#000, 0.4);
    }
  }
}

.matchTypes {
  position: absolute;
  top: 3vmin;
  right: 4vmin;
  display: flex;
  align-items: center;

  span {
    min-width: 12vmin;
    margin: 0 1vmin;
    padding: 0.5vmin 1vmin;
    background-color: #fff;
    border: 1px solid #000;
    font-size: 2vmin;
    font-weight: 500;
    text-align: center;
  }

  button {
    height: 3.5vmin;
    transition: all 0.1s ease-in-out;
    opacity: 0.8;

    img {
      max-width: none;
      width: auto;
      height: 100%;
    }

    &:hover {
      transform: translateY(-1px);
    }

    &:active {
      transform: translateY(1px);
    }
  }
}

.cardsBox {
  position: absolute;
  top: 43.5vmin;
  left: 50%;
  transform: translate(-50%, -50%);
}

.cardsWrapper {
  display: flex;
  flex-wrap: wrap;
  width: 80vmin;
  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;

  &--12 {
    .card {
      width: 20vmin;
      height: 20vmin;
      font-size: 1.1vmin;
    }
  }

  &--16 {
    width: 64vmin;
    .card {
      width: 16vmin;
      height: 16vmin;
      font-size: 0.9vmin;
    }
  }

  &--20 {
    .card {
      width: 16vmin;
      height: 16vmin;
      font-size: 0.9vmin;
    }
  }

  &--24 {
    .card {
      width: 13.33vmin;
      height: 13.33vmin;
      font-size: 0.8vmin;
    }
  }
}

.card {
  perspective: 1000px;
  position: relative;
  cursor: pointer;
  user-select: none;

  &--audio .cardBack {
    img {
      max-width: 80%;
      max-height: 80%;
    }
  }

  &--text .cardBack {
    font-size: 2em;
    user-select: none;

    &.textUpTo2 {
      font-size: 11em;
    }
    &.textUpTo4 {
      font-size: 5.5em;
    }
    &.textUpTo6 {
      font-size: 3.8em;
    }
    &.textUpTo10 {
      font-size: 3.2em;
    }
  }

  &--image .cardBack {
    img {
      max-width: 100%;
      max-height: 100%;
    }
  }
}

.cardSide {
  position: absolute;
  top: 0;
  left: 0;
  width: 100%;
  height: 100%;
  transition: all 0.7s ease-in-out;
  transform-style: preserve-3d;
  backface-visibility: hidden;
  box-shadow: inset 0 0 5px 1px rgba(0, 0, 0, 0.8);
}

.cardFront {
  z-index: 9;
  transform: rotateX(0deg) rotateY(0deg);

  background-position: center;
  background-size: cover;
  background-repeat: no-repeat;
}

.cardBack {
  background-color: white;
  z-index: 8;
  transform: rotateY(-180deg);

  display: flex;
  align-items: center;
  justify-content: center;
}

.cardOpened .cardFront {
  z-index: 10;
  transform: rotateY(180deg);
}

.cardOpened .cardBack {
  z-index: 11;
  transform: rotateX(0deg) rotateY(0deg);
}

.cardResolved {
  z-index: 10;
  animation: hideCard 2s ease-in-out forwards;

  .cardFront {
    animation: flipCardFront 0.8s ease-in-out infinite;
  }

  .cardBack {
    animation: flipCardBack 0.8s ease-in-out infinite;
  }
}

@keyframes flipCardFront {
  0% {
    z-index: 10;
    transform: rotateY(180deg);
  }

  50% {
    z-index: 9;
    transform: rotateX(0deg) rotateY(360deg);
  }

  100% {
    z-index: 10;
    transform: rotateY(540deg);
  }
}

@keyframes flipCardBack {
  0% {
    z-index: 11;
    transform: rotateX(0deg) rotateY(0deg);
  }

  50% {
    z-index: 8;
    transform: rotateY(180deg);
  }

  100% {
    z-index: 11;
    transform: rotateX(0deg) rotateY(360deg);
  }
}

@keyframes hideCard {
  0% {
    transform: scale(1);
  }

  60% {
    transform: scale(1.15);
  }

  99% {
    visibility: visible;
  }

  100% {
    transform: scale(0);
    visibility: hidden;
  }
}
