.wrapper {
  display: flex;
  margin-top: 20px;
  justify-content: space-between;
  margin-bottom: 18px;
}

.label {
  // display: block;
  // font-size: 14px;
  font-weight: 500;
  width: 100%;
}

.textareaWrapper {
  display: flex;
  flex-wrap: wrap;
}

.imageWrapper {
  margin: 0;
  margin-right: 20px;
}

.audioWrapper {
  margin: 0;
}

.textarea {
  width: 120px;
  height: 60px;
  margin-right: 20px;
}

img.deleteButton {
  margin: -20px 30px 30px -10px;
  width: 20px;
}