.positionedBox {
  top: 18%;
  position: absolute;
  left: 5%;
  max-width: 14%;
  display: flex;
  justify-content: center;
}

.wrapper {
  max-width: 100%;
}

.innerWrapper {
  max-width: 100%;

  img {
    display: block;
  }
}
