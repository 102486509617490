.wrapper {
  display: flex;
  align-items: center;
  margin: 0;
  flex-grow: 1;
}

.text {
  font-size: 16px;
  margin-left: 30px;
  margin-right: 30px;
}

.addBtnWrapper {
  display: flex;
  flex-direction: column;
  font-weight: 500;
  margin-right: 10px;

  &.disabled {
    opacity: 0.7;

    label {
      cursor: not-allowed;
    }
  }

  input {
    display: none;
  }

  label {
    cursor: pointer;
  }
}
