.list {
  list-style: none;
  margin: 0;
  padding: 0;
  text-align: center;

  li {
    cursor: pointer;
    line-height: 1.2;
    // margin: 0.3vmin 0;
    margin: 3px 0;

    &:hover:not(.active) {
      color: limegreen;
    }

    &.active {
      color: limegreen;
    }
  }
}
