.inFocus {
  z-index: 100;

  .removeButton,
  .moveButton,
  .orderButton {
    opacity: 1;
    visibility: visible;
  }

  .removeButton {
    transform: translateX(-100%);
  }

  .moveButton {
    transform: translateX(100%);
  }

  .orderButton {
    transform: translate(100%, 100%);
    &.ignoreTTS {
      color: darken(gray, 25) !important;
      background: repeating-linear-gradient(
        45deg,
        gray 2px,
        gray 2px,
        transparent 6px,
        transparent 3px
      );
    }
  }
}

.editorToolbar {
  z-index: 1;
  position: absolute;
  top: 0;
  transform: translateY(-50%);
  background-color: rgba(#fff, 0.95);
  visibility: hidden;
  opacity: 0;
  transition: all 0.5s ease;

  &.visibleToolbar {
    opacity: 1;
    transform: translateY(-100%);
    visibility: visible;
  }
}

.wrapper {
  position: relative;
  height: 100%;

  // &.disabled {
  //   .removeButton,
  //   .moveButton,
  //   .editorToolbar {
  //     display: none !important;
  //   }

  //   .editor {
  //     // background: none;
  //   }
  // }
}

.editor {
  position: relative;
  background-color: rgba(#fff, 0.95);
  height: 100%;
  font-size: 16px;
}

.hidden {
  display: none;
}

.removeButton,
.moveButton,
.orderButton {
  position: absolute;
  background-color: #fff;
  cursor: pointer;
  border: 1px solid #ccc;
  height: 20px;
  width: 20px;

  visibility: hidden;
  opacity: 0;
  top: 0;
  transition: all 0.5s ease;
}

.removeButton {
  left: 0;
}

.moveButton {
  right: 0;
}

.orderButton {
  right: 0;
}