.options {
  display: flex;
  flex-wrap: wrap;
  margin-top: 10px;
  margin-bottom: 10px;
  padding-bottom: 10px;
  justify-content: space-between;
  border-bottom: 1px solid rgba(0, 0, 0, 0.4);
}

.fontFamilySelect {
  margin-right: 20px;
  label {
    margin-right: 10px;
  }
}

.fontSizeSelect {
  margin-right: 20px;
  label {
    margin-right: 10px;
  }
}

.itemsShownSelect {
  label {
    margin-right: 10px;
  }
}

.radioSelect {
  width: 100%;
  margin-top: 5px;
  margin-bottom: 5px;
}

.radioLabel {
  cursor: pointer;
  margin-right: 30px;
  user-select: none;

  input {
    margin-right: 5px;
  }
}

.fontControls {
  display: flex;
  flex-wrap: wrap;
  align-items: center;

  label {
    margin-right: 10px;
  }
}