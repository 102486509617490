.datePicker {
  font-size: 14px !important;
  font-family: inherit !important;

  :global(.react-datepicker__header) {
    font-size: 14px;
    padding-bottom: 7px;
    padding-top: 7px;
  }

  :global(.react-datepicker__current-month) {
    font-size: 14px;
    font-weight: 500;
  }

  :global(.react-datepicker__day-name) {
    width: 20px;
    margin: 4px;
  }
  :global(.react-datepicker__day) {
    width: 20px;
    margin: 4px;
  }
}
