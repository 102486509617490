$blue: var(--blue);
$purple: var(--purple);

// Form
.loginForm {
  font-size: 1.35vw;

  margin-top: auto;
  display: flex;
  flex-direction: column;
  color: var(--blue);
}

.loginsWrapper {
  display: flex;
  margin-bottom: 3.3vw;
}

.buttonsWrapper {
  display: flex;
  position: relative;
}

.button {
  cursor: pointer;
  min-width: 20vw;
  height: 4vw;
  text-align: center;
  display: flex;
  align-items: center;
  justify-content: center;
  padding: 0.5vw;

  border-radius: 1vw;
  color: #fff;
  text-transform: uppercase;
  font-size: 1.2vw;
  font-weight: bold;
  margin-right: 1.5vw;
  transition: all 0.1s ease;

  &.purple {
    background-color: $purple;

    &:hover:not(:active) {
      box-shadow: 0 0 0.6vw 1px $purple;
    }
  }

  &.blue {
    background-color: $blue;

    &:hover:not(:active) {
      box-shadow: 0 0 0.6vw 1px $blue;
    }
  }
}

.radioWrapper {
  display: flex;
  margin-bottom: 2.6vw;
}

.radioLabel {
  margin-right: 3.5vw;
  cursor: pointer;
  padding-left: 2vw;
  position: relative;
  font-size: 1.6vw;

  // @media (orientation: portrait) and (max-width: 565px) {
  //   font-size: 3.5vw;
  //   padding-left: 4vw;
  // }

  $circleBase: 1.24vw;

  &:hover {
    color: var(--purple);
  }

  &::after,
  &::before {
    content: '';
    position: absolute;
  }

  &::before {
    left: 0;
    top: 50%;
    transform: translateY(-50%);
    width: $circleBase;
    height: $circleBase;
    background-color: #fff;
    border-radius: 50%;
  }

  &::after {
    top: 50%;
    transform: translate(-50%, -50%);
    left: calc($circleBase / 2);
    width: calc($circleBase / 2 + 0.13vw);
    height: calc($circleBase / 2 + 0.13vw);
    background-color: rgba(#000, 0.1);
    border-radius: 50%;
  }

  &.checked {
    &::after {
      background-color: var(--blue);
    }
  }

  input {
    display: none;
  }
}

.phone {
  height: min-content;
  font-size: 1.2vw;
  color: #000;
  background-color: #fff;
  padding: 1vw;
  border-radius: 1vw;
}

.helpAndPhoneWrapper {
  align-items: center;
  margin-left: auto;
  margin-top: auto;
  display: flex;
  flex-direction: row;
  gap: 1em;
}
