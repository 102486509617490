.wrapper {
  width: 100%;
}

.inner {
  display: block;
}

.grid {
  --grid-width: 90vmin;
  --grid-height: 60vmin;

  height: var(--grid-height);

  display: flex;
  flex-direction: column;

  border-radius: 0;

  &.withBorder,
  &.withBorder .itemWrapper {
    border: 1px solid rgba(#000, 0.5);
  }

  &.isResolved .itemWrapper.selected button {
  }
}

.row {
  width: var(--grid-width);
  flex-grow: 1;
  // height: 100%;
  display: flex;
}

.itemWrapper {
  display: flex;
  align-items: center;
  justify-content: center;
  background-color: #fff;
  width: 100%;

  button {
    position: relative;
    background-color: #fff;
    width: 100%;
    height: 100%;
    transition: all 0.2s ease;

    display: flex;
    align-items: center;
    justify-content: center;
  }

  img {
    max-width: 80%;
    max-height: 80%;
    user-select: none;

    object-fit: contain;
  }

  &:not(.highlighted):not(.selected):hover {
    button {
      box-shadow: inset 0 0 1.2vmin 0.1vmin rgba(green, 0.4);
    }
  }

  &.highlighted {
    button {
      pointer-events: none;
      box-shadow: inset 0 0 1.2vmin 0.1vmin yellow;
    }
  }

  &.selected {
    button {
      z-index: 1;
      box-shadow: inset 0 0 1.2vmin 0.1vmin green;
    }
  }
}

.text {
  font-size: 2.5vmin;
  text-align: center;

  &.textUpTo2 {
    font-size: 9vmin;
  }
  &.textUpTo4 {
    font-size: 5vmin;
  }
  &.textUpTo6 {
    font-size: 4vmin;
  }
  &.textUpTo10 {
    font-size: 3.5vmin;
  }
  &.textUpTo20 {
    font-size: 3vmin;
  }
}
