.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto;
  position: relative;
  min-height: 50vmin;

  h4 {
    font-size: 3vmin;
    text-align: center;
    margin: 0;
    font-weight: 500;
    margin-bottom: 2vmin;
  }

  th {
    font-weight: 500;
  }

  table {
    margin-bottom: 2vmin;
    width: 100%;
  }

  table,
  th,
  td {
    border: 1px solid #ddd;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 1vmin 2vmin;
    font-size: 2vmin;
    text-align: left;
  }

  p {
    font-size: 2vmin;
  }
}

.blink {
  animation: blink 1s linear infinite;
  color: red;
}

.hoursInput {
  width: 80px;
  margin-right: 20px;
}

.cellWithAction {
  display: flex;
  align-items: center;

  button {
    margin-left: auto;
  }
}

.paymentAmount {
  text-align: center;
  display: block;
  font-size: 2vmin;
  font-weight: 500;
}

.mpesaPaymentBlock {
  margin-top: 3vmin;
  font-size: 2vmin;
  display: flex;
  flex-direction: column;
  align-items: center;

  input {
    width: 15vmin;
    padding: 0.5vmin 1vmin;
  }
  
  .helper {
    margin-top: 1vmin;
    font-size: 1.5vmin;
  }

  button {
    margin-top: 2vmin;
  }
}

.paymentFormWrapper {
  position: absolute;
  background-color: #fff;
  top: 0;
  bottom: 0;
  left: 0;
  right: 0;
}

.note {
  font-size: 70%;
}

@keyframes blink {
  0% {
    opacity: 0;
  }

  50% {
    opacity: 1;
  }

  100% {
    opacity: 0;
  }
}
