.df {
  display: flex;
}

.fxdc {
  flex-direction: column;
}

.aic {
  align-items: center;
}

.jcsb {
  justify-content: space-between;
}

.jcc {
  justify-content: center;
}

.grow-1 {
  flex-grow: 1;
}

.grow-0 {
  flex-grow: 0;
}