@import url('https://fonts.googleapis.com/css2?family=Montserrat:wght@500;700&display=swap');
$purple: #8a2380;
$blue: #0080c1;
$img-width: 55vw;

.wrapper {
  --font-base: 'Montserrat', sans-serif;
  background-image: linear-gradient(to top, #98e7f3, #47d9e9 46%, #36a0ff);

  position: absolute;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;

  overflow-y: auto;

  &.hidden {
    visibility: hidden;
  }
}

.inner {
  overflow: hidden;
  margin-top: auto;
  margin-bottom: auto;
  position: relative;
  padding: 3.375vw;
  font-weight: 500;
  display: flex;
  flex-direction: column;
  min-height: 100vh;
  // height: 100vh;
  z-index: 1;
}

.bgImg {
  position: absolute;
  right: 0;
  bottom: 0;
  z-index: -1;
  height: 41vw;

  svg {
    opacity: 0.7;
    height: 100%;
    width: auto;
    max-width: 100%;
    object-fit: contain;
  }

  img {
    height: 100%;
    width: auto;
    opacity: 0.6;
    filter: url(#mask);
    object-fit: contain;
  }
}

.top {
  position: relative;
  display: flex;
  justify-content: space-between;
  margin-bottom: 5vw;

  &::after {
    content: '';
    width: 25.875vw;
    height: 25.875vw;
    background-color: #fff;
    position: absolute;
    border-radius: 50%;
    top: 0;
    left: 0;
    transform: translate(-23%, -45%);
    z-index: -1;
  }
}

.logo {
  margin-right: 5vw;
  flex-shrink: 0;
  width: 14.625vw;

  a {
    display: block;
  }

  img {
    width: 100%;
  }
}

// Help
.helpBlock {
  align-self: start;
  transform-origin: top;
  margin-left: 2vw;
  margin-right: 2vw;
}

.helpWrapper {
  width: 41vw;
  flex-shrink: 0;
  position: relative;
  padding: 1vw 1vw 1vw;
  background: #fff;
  border-radius: 0.84vw;
  margin: 0 auto;
}

.helpText {
  color: $purple;
  font-size: 1.2vw;

  strong {
    display: block;
    margin-bottom: 0.3vw;
  }

  p {
    margin: 0;
  }

  *:last-child:not(strong) {
    margin-bottom: 0;
  }
}

.playButton {
  position: absolute;
  right: 0;
  top: 0;
  transform: translate(50%, -50%);
  background-color: #fff;
  padding: 0.8vw;
  border: 0.25vw solid $purple;
  border-radius: 50%;
  overflow: hidden;

  width: 4.275vw;
  height: 4.275vw;

  display: flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;

  &:active,
  &:focus {
    outline: none;
  }

  img {
    display: block;
    user-select: none;
  }

  &:hover {
    // background-color: lighten($blue, 50%);
    img {
      transition: all 0.2s ease;
      transform: scale(1.1);
    }
  }

  .pauseIcon {
    position: absolute;
    bottom: -0.5vw;
    right: 0.2vw;
    font-size: 3vw;
    font-weight: bold;
    color: red;
    user-select: none;
    text-shadow: 0 0 2px #fff;
    transform: scale(0);
    transition: all 0.2s ease;
  }

  &.playing .pauseIcon {
    transform: scale(1);
  }
}

.centerName {
  color: #fff;
  font-size: 2.25vw;
  line-height: 1.1;
  margin: 0;
  margin-left: 2.8vw;
  margin-right: 2.8vw;

  text-transform: uppercase;

  span {
    display: block;
  }
}

// @media (orientation: portrait) and (max-width: 767px) {
//   .bgImg {
//     top: 30vw;
//     // bottom: auto;
//     // top: 0;
//     // width: 100vw;
//     // top: 50%;
//     // transform: translateY(-50%);
//   }
// }

// @media (orientation: portrait) and (max-width: 1365px) {
//   .top {
//     flex-wrap: wrap;
//   }

//   .helpBlock {
//     order: 3;
//     margin-top: 5vw;
//     width: 100%;
//   }

//   .helpWrapper {
//     transform: scale(1.5);
//   }
// }

// @media (orientation: portrait) and (max-width: 767px) {
//   .helpBlock {
//     margin-top: 10vw;
//   }

//   .helpWrapper {
//     transform: scale(1.5);
//   }

//   .centerName {
//     font-size: 3.5vw;
//   }

//   .logo {
//     width: 20vw;
//   }

//   .top {
//     position: relative;
//     display: flex;
//     justify-content: space-between;
//     margin-bottom: 5vw;

//     &::after {
//       width: 30vw;
//       height: 30vw;
//       transform: translate(-20%, -45%);
//     }
//   }
// }

// @media (orientation: portrait) and (max-width: 567px) {
//   .top {
//     margin-bottom: 10vw;
//   }

//   .helpBlock {
//     margin-top: 15vw;
//   }

//   .helpWrapper {
//     transform: scale(2.2);
//   }

//   .logo {
//     width: 17vw;
//   }
// }

// @media (max-height: 800px) {
//   .top::after {
//     transform: translate(-25%, -55%);
//   }
// }

// @media (max-height: 400px) and (max-width: 767px) {
//   .top::after {
//     transform: translate(-25%, -55%);
//   }
// }
