.form {
  &__group {
    padding: 0;
    border: none;
    margin-bottom: 12px;

    &--radio {
      display: flex;
      flex-wrap: wrap;
      justify-content: center;

      label {
        width: 40%;
        margin-top: 10px;
        cursor: pointer;
        display: flex;
      }

      input[type='radio'] {
        margin-right: 5px;
      }

      .form__label {
        width: 100%;
      }
    }
  }

  &__label {
    font-weight: 500;
    margin: 5px 0 2px 0;
    padding: 0;
    font-size: 14px;
    color: black;
    display: block;
  }

  input[type='text'] {
    border-radius: 4px;
    border: 1px solid gray;
    padding: 3px 5px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.75);
    display: block;
    font-size: 14px;
    font: inherit;
    width: 100%;
  }

  span {
    border-radius: 4px;
    border: 1px solid gray;
    padding: 3px 5px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.75);
    display: block;
    font-size: 14px;
    font: inherit;
    width: 100%;
  }

  select {
    border-radius: 4px;
    border: 1px solid gray;
    padding: 1px 5px;
    box-shadow: inset 0 1px 2px rgba(0, 0, 0, 0.1);
    color: rgba(0, 0, 0, 0.75);
    display: block;
    font-size: 14px;
    font: inherit;
    width: 100%;
    margin-top: 5px;
    margin-bottom: 10px;
  }

  &__fieldset {
    padding-top: 15px;
    padding-bottom: 15px;

    &:not(:first-child) {
      margin-top: 30px;
    }

    legend {
      text-align: center;
      font-weight: 500;
    }
  }
}

.multipleBlock {
  display: flex;
  align-items: center;
  margin-top: 10px;
  margin-bottom: 10px;
  height: 30px;
  font-size: 14px;

  input {
    margin-right: 5px;
  }

  label {
    margin-right: 20px;
    cursor: pointer;
  }
}

.btn {
  padding: 5px 10px;
  border: 1px solid #000;
  border-radius: 5px;

  &:hover:not(:disabled) {
    background-color: lightblue;
  }
}
