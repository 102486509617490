.btn {
  display: inline-flex;
  flex-direction: column;
  align-items: center;
  align-self: flex-start;
  cursor: pointer;
  opacity: 0.7;
  margin-bottom: 30px;
  margin-right: 50px;

  span {
    font-weight: 400;
  }

  &:hover:not(:disabled) {
    opacity: 1;
  }

  span {
    margin-bottom: 5px;
  }

  img {
    height: 48px;
  }

  input[type="file"] {
    display: none;
  }
}

.header {
  display: flex;
}

.listsWrapper {
  display: flex;
  justify-content: space-between;

  > * {
    width: 45%;
  }
}

.wordsList {
  list-style: none;
  margin: 0;
  padding: 0;
}

.wordField {
  overflow-x: auto;
  height: 35px;
  padding: 5px;
  margin-top: 10px;
  background-color: #fff;
  border: 1px solid #d8d8d8;
  width: 100%;
}
