.itemMixed {
  width: 13vmin;
  height: 19vmin;

  cursor: pointer;
  opacity: 1;
  user-select: none;
  position: relative;
  margin: 1vmin;
  border: 0.3vmin solid transparent;
  border-radius: 5px;
  overflow: hidden;
}

.inner {
  height: 100%;
  width: 100%;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  padding: 1vmin 1vmin;
}

.textValue {
  max-width: 100%;
  hyphens: auto;
}

.playAudioBtn {
  position: relative;

  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  position: absolute;
  width: 8vmin;
  height: 8vmin;

  img {
    display: block;
  }
}

.playAudioBtnSmall {
  position: absolute;
  width: 4vmin;
  height: 4vmin;
  bottom: 1vmin;
  right: 1vmin;

  img {
    display: block;
  }
}

.itemImg {
  max-height: 100%;
}

.question.itemMixed {
  width: 21vmin;
  height: 28vmin;
  margin: 0;
  cursor: default;

  .textValue {
    font-size: 3.5vmin;

    &.textUpTo2 {
      font-size: 11vmin;
    }
    &.textUpTo4 {
      font-size: 8vmin;
    }
    &.textUpTo6 {
      font-size: 5.5vmin;
    }
    &.textUpTo10 {
      font-size: 4.2vmin;
    }
  }
}
