.outer {
  background: rgba(#fff, 0.75);
  padding: 1.5vmin;
  border-radius: 3vmin;
  display: flex;

  &.pixels {
    padding: 13px;
    border-radius: 26px;

    .inner {
      padding: 13px;
      border-radius: 13px;
    }
  }
}

.inner {
  flex-grow: 1;
  background: rgba(#fff, 0.8);
  padding: 1.5vmin;
  border-radius: 1.5vmin;
}
