.add-audio-modal {
  padding: 0 10px;
  width: 500px;
  min-height: 250px;
  display: flex;
  justify-content: space-between;
  align-items: flex-start;
}

.btn {
  margin-right: 15px;

  img {
    max-height: 100%;
  }
}

.tts-btn {
  &:disabled img { 
    pointer-events: none !important;
  }

  &:disabled {
    cursor: default;
    opacity: 0.8;
    filter: grayscale(1);
    pointer-events: none;
  }
}

.label {
  font-weight: 500;
  // font-size: 18px;
  display: block;
}

.dropzone-wrapper {
  height: 220px;
  width: 100%;
}

.buttons-wrapper {
  display: flex;
  align-items: center;
}

.dropzone {
  border: 2px dashed #333;
  padding: 10px;
  width: 100%;
  height: 150px;
  cursor: pointer;
  transition: border 0.2s ease;
  display: flex;
  align-items: center;
  justify-content: center;

  &--active {
    border-color: #0080ff;
  }
}

.upload-button {
  cursor: pointer;
  padding: 3px 16px;
  border: 1px solid #ccc;
  border-radius: 5px;
  text-align: center;
  margin-top: -10px;

  &:hover {
    border-color: #0080ff;
    background-color: #0080ff;
    color: #fff;
  }
}

.row {
  margin-top: 5px;
  width: 100%;
  display: flex;
  vertical-align: center;
  gap: 10px;
}

.tts-picker {
  width: min-content;
}

.col {
  width: 45%;

  h3 {
    margin-top: 0;
  }
}

.record-wrapper {
  display: flex;
  flex-direction: column;

  button {
    display: flex;
    align-items: center;
    font-size: 16px;
    margin-bottom: 20px;

    img {
      margin-right: 5px;
    }
  }
}
.radioGroup {
  display: flex;
  align-items: center;
  cursor: pointer;
  user-select: none;
  input {
    margin-right: 15px;
    height: 18px;
  }
}
