.answersWrapper {
  position: absolute;
  top: 66vmin;
  left: 50%;
  transform: translateX(-50%);
  width: 70vmin;
  z-index: 1;
}

.answersInner {
  display: flex;
  flex-wrap: wrap;
  align-items: center;
  padding: 0;
  height: 16vmin;
  justify-content: center;

  // overflow-y: scroll;
}

.boardBox {
  margin: 0 1.5vmin;
}
