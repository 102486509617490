.itemMixed {
  width: 180px;
  height: 180px;

  padding: 10px;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 1;
  user-select: none;
  position: relative;
}

.itemText {
  display: inline-block;
}

.itemImg {
  display: inline-block;
}

.itemAudio {
  display: inline-block;
}

.playAudioBtn {
  position: relative;
  width: 90%;
  height: 90%;

  img {
    display: block;
  }
}

.playAudioBtnSmall {
  position: absolute;
  width: 30px;
  height: 30px;
  bottom: 0;
  right: 0;

  img {
    display: block;
  }
}

.itemImg {
  width: 90%;
  height: 90%;
  object-fit: contain;
}
