.overlay {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#000, 0.5);
  z-index: 100;
}

.modal {
  font-size: 2vmin;
  min-width: 40vmin;
}

.fixedPosition {
  position: fixed;
  top: 43.5%; // Top of Buttons is 13%, 100% - 13% = 87% / 2 = 43.5%
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 110;
}

.modal-inner {
  padding: 2vmin;
  background: #fff;
  border-radius: 2vmin;
  max-height: 70vh;
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.title {
  font-weight: 500;
  color: #000000;
  text-align: center;
  font-size: 2.8vmin;
  margin-bottom: 2vmin;
}

.content {
  overflow-y: auto;
  flex-grow: 1;
  margin-bottom: 2vmin;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:horizontal {
    height: 1vmin;
  }

  &::-webkit-scrollbar:vertical {
    width: 1vmin;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.25);
    // border: 2px solid #fff;
    border-radius: 1vmin;
  }
}

.buttons {
  display: flex;
  justify-content: flex-end;
  align-items: center;

  button {
    padding: 0.4vmin 2vmin;
    // height: 28px;
    font-weight: 500;
    border-radius: 1vmin;
    font-size: 1.8vmin;
    border: 1px solid #000;

    &:not(:first-child) {
      margin-left: 2vmin;
    }

    &:hover:not(:disabled) {
      color: #fff;
      background-color: rgba(#0080ff, 0.3);
      border-color: rgba(#0080ff, 0.3);
    }

    img {
      display: block;
      height: 3vmin;
    }
  }

  &.column {
    flex-direction: column;

    button {
      width: 100%;
      margin-left: 0;

      &:not(:last-child) {
        margin-bottom: 1vmin;
      }
    }
  }
}
