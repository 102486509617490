.positionedBox {
  position: relative;
  z-index: 1;

  user-select: none;
}

.wrapper {
  max-width: 100%;
  height: 100%;
  display: flex;
  padding: 15px;
}

.innerWrapper {
  max-width: 100%;
  display: flex;
  flex-direction: column;
  padding: 15px;
  
  > :first-child {
    order: 5;
  }
}

.fadeIn {
  opacity: 0;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
}

.fadeOut {
  opacity: 1;
  animation-name: fadeOut;
  animation-fill-mode: forwards;
}

@keyframes fadeIn {
  0% {
    visibility: hidden;
    opacity: 0;
  }
  100% {
    visibility: visible;
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    visibility: visible;
    opacity: 1;
  }
  100% {
    visibility: hidden;
    opacity: 0;
  }
}
