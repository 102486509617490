.wrapper {
  display: flex;
  flex-direction: column;
  align-items: center;
  width: 80%;
  margin: 0 auto;

  h4 {
    font-size: 3vmin;
    text-align: center;
    margin: 0;
    font-weight: 500;
    margin-bottom: 2vmin;
  }

  th {
    font-weight: 500;
  }

  table {
    margin-bottom: 2vmin;
    width: 100%;
  }

  table,
  th,
  td {
    border: 1px solid #ddd;
    border-collapse: collapse;
  }

  th,
  td {
    padding: 1vmin 2vmin;
    font-size: 2vmin;
    text-align: left;
  }

  p {
    font-size: 2vmin;
  }
}

.statusCell {
  display: flex;
  align-items: center;
  justify-content: space-between;

  button {
    cursor: pointer;
    color: blue;
  }
}