.wrapper {
  position: absolute;
  left: 50%;
  top: 43.5vh;
  transform: translate(-50%, -50%);
}

.innerWrapper {
  position: relative;
  min-width: 92vmin;
  max-width: 92vw;
}

.header {
  // height: 10vmin;
  display: flex;
  flex-direction: row;
  position: relative;
  text-align: center;
  padding: 0 16vmin;
  justify-content: space-evenly;
}

.titleAndSubtitleWrapper {
  display: flex;
  flex-direction: column;
  text-align: center;
}

.logoWrapper {
  width: 15vmin;
  text-align: start;
  max-height: 10vmin;
  height: 100%;
  position: absolute;
  top: 0;
  left: 0;
}

.logoWrapper img {
  max-width: 100%;
  max-height: 100%;
  object-fit: contain;
}

.menuTitle {
  font-family: Impact, sans-serif;
  font-size: 3.8vmin;
  font-weight: 500;
  margin: 0 0 0.8vmin;
}

.menuSubtitle {
  font-family: 'Roboto', sans-serif;
  font-size: 2.4vmin;
  font-weight: 500;
  margin: 0;
}

.menuTitle p, .menuSubtitle p {
  margin: 0;
}

.tableHeading {
  display: flex;
  align-items: flex-end;
  text-align: center;
  vertical-align: bottom;
  font-size: 2.2vmin;
  font-weight: 500;
  margin-bottom: 1vmin;

  > * {
    flex-grow: 1;

    &:first-child {
      width: 15vmin;
      align-self: stretch;
      flex-grow: 0;
      flex-shrink: 0;
      display: flex;
      flex-direction: column;
      justify-content: flex-end;
    }
  }

  figure {
    width: 15vmin;
    margin: 0 2vmin;

    img {
      max-height: 12vmin;
      max-width: 70%;
      margin-bottom: 1vmin;
    }
  }
}

.tableBody {
  max-height: 48vmin;
  overflow-y: auto;
  direction: rtl;
}

.levelRow {
  direction: ltr;
  display: flex;
  height: 5.2vmin;
  align-items: center;
}

.highlight {
  position: relative;

  &:after {
    content: '';
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
    width: 100%;
    height: 100%;
    background-color: rgba(#0080ff, 0.5);
    border-radius: 2vmin;
    // animation: pulse 2s ease-in-out infinite;
  }
}

// @keyframes pulse {
//   50% {
//     opacity: 0;
//   }
// }

.levelTitle {
  font-size: 2.3vmin;
  flex-shrink: 0;
  font-weight: 500;
  width: 15vmin;
  text-align: center;
}

.cell {
  text-align: center;
  margin: 0 2vmin;
  width: 100%;
  position: relative;

  .levelName {
    position: absolute;
    top: 50%;
    transform: translateY(-50%);
    left: -4vmin;
    right: -4vmin;
    text-align: left;
    font-size: 2vmin;
  }
}

.menuGroupBtn {
  width: 10vmin;
  height: 4.7vmin;
  border-radius: 2vmin;
  border: 1px solid var(--purple);
  background-color: #bfe5e2;
  transition: background-color 0.1s ease;
  text-decoration: none;

  display: inline-flex;
  align-items: center;
  justify-content: center;

  > * {
    max-height: 4.5vmin;
    margin: 0 1px;
  }

  img {
    display: inline-block;
    max-height: 4.5vmin;
  }

  &:disabled {
    cursor: default;
  }

  &:hover:not(:disabled) {
    background-color: darken(#bfe5e2, 15%);
  }

  &.partial .star {
    color: yellow;
  }

  &.mastered .star {
    color: limegreen;
  }
}

.levelsIndicator {
  text-align: left;

  &.hidden {
    visibility: hidden;
  }
}

.star {
  color: #fff;
  font-size: 3vmin;
}

.legend {
  list-style: none;
  margin: auto 0 2vmin;
  padding: 0;

  li {
    display: flex;
    align-items: center;

    font-size: 1.7vmin;
  }

  li:not(:first-child) {
    margin-top: 2vmin;
  }
}

.legendStar {
  padding: 0.2vmin 1vmin;
  flex-shrink: 0;
  margin-right: 1vmin;
  font-size: 3vmin;
  border-radius: 0.5vmin;
  border: 1px solid #c3c7d7;
  background-color: #bfe5e2;
  display: inline-flex;

  img {
    height: 3vmin;
  }
}

.footer {
  margin-top: 1vmin;
  display: flex;
  align-items: flex-end;
  font-size: 2.2vmin;
}

.learnerStarLegend, .timeSpentPreviousWeek {
  display: flex;
  margin-left: auto;
}

.learnerStarLegendItem {
  display: flex;
  align-items: center;
  margin-right: 3vmin;

  span {
    margin-right: 1vmin;
  }

  img {
    height: 4vmin;
  }
}
