.global-help {
  &__content-wrapper {
    position: fixed;
    left: 0;
    top: 0;
    width: 250px;
    z-index: 10;
    height: 0;
  }

  &__button {
    position: absolute;
    right: 20px;
    top: 20px;

    background-color: transparent;
    border: none;
    width: 60px;
    height: 60px;
    padding: 0;
    cursor: pointer;

    &:hover {
      transform: scale(0.98);
    }

    &:focus {
      outline: none;
    }

    &:active {
      transform: scale(0.9);
    }

    img {
      width: 100%;
      height: 100%;
    }
  }

  &__content {
    width: 250px;
    cursor: -webkit-grab;
    cursor: grab;
    padding: 5px;
    border: 1px solid rgba(0, 0, 0, 0.4);
    background-color: white;
    overflow-y: auto;
    font-size: 16px;
    height: 700px;
    max-height: 90vh;

    &::-webkit-scrollbar {
      -webkit-appearance: none;
    }

    &::-webkit-scrollbar:horizontal {
      height: 11px;
    }

    &::-webkit-scrollbar:vertical {
      width: 11px;
    }

    &::-webkit-scrollbar-thumb {
      background-color: rgba(0, 0, 0, 0.25);
      border: 2px solid #fff;
      border-radius: 8px;
    }

    dt {
      color: #0000ff;
      font-weight: 500;
    }

    dd {
      margin-left: 0;
      margin-bottom: 5px;
    }
  }
}
