.wrapper {
  height: 100%;
  display: flex;
  flex-direction: column;
}

.actions {
  display: flex;
  align-items: center;
  justify-content: flex-start;
  height: 8%;
  border-bottom: 5px solid #ddecf6;
  flex-shrink: 0;

  button {
    user-select: none;
    width: 10%;
    display: flex;
    align-items: center;
    height: 90%;
    margin: 0 2%;
    opacity: 0.8;

    &:hover {
      opacity: 1;
    }

    img {
      max-height: 100%;
      width: 100%;
      height: auto;
    }

    &:last-child {
      margin-left: auto;
    }
  }
}

.hidden {
  visibility: hidden;
}

.content {
  flex-grow: 1;
  display: block;
  hyphens: auto;
  font-family: Arial;
  overflow: hidden;
}

.bottom {
  border-top: 5px solid #ddecf6;
  height: 6%;
  display: block;

  font-size: 18px;
  display: flex;
  align-items: center;
  justify-content: center;
  flex-shrink: 0;
}
