.wrapper {
  position: absolute;
  top: 50px;
  left: 35%;
}

.radioGroup {
  display: flex;
  align-items: center;
  margin-bottom: 5px;
  cursor: pointer;
  user-select: none;
  input {
    margin-right: 15px;
    height: 18px;
  }
}