.box {
  margin-right: 3.7vmin;
}

.inner {
  padding: 0;

  > * {
    margin: 0;
  }
}