.canDrop {
  background-color: rgba(#0080ff, 0.5);
}

.join {
  transition: all 1s ease !important;
  border-color: transparent !important;
}

.joinFirst {
  border-right: none !important;
  border-top-right-radius: 0 !important;
  border-bottom-right-radius: 0 !important;
  margin-right: 0 !important;
  justify-content: flex-end !important;

  * {
    padding-right: 0 !important;
  }
}

.joinLast {
  border-left: none !important;
  border-top-left-radius: 0 !important;
  border-bottom-left-radius: 0 !important;
  margin-left: 0 !important;
  justify-content: flex-start !important;

  * {
    padding-left: 0 !important;
  }
}

.hidden {
  visibility: hidden !important;
}

.joinMiddle {
  @extend .joinFirst;
  @extend .joinLast;

  width: auto !important;
  justify-content: center !important;
}
