.wrapper {
  margin-bottom: 20px;
}

.categorySelectBox {
  display: flex;
  align-items: center;
  justify-content: center;
  margin-bottom: 20px;

  label {
    margin-right: 15px;
  }

  select {
    min-width: 200px;
    cursor: pointer;
  }
}

.categoriesColumnsWrapper {
  display: flex;
  justify-content: space-between;
}

.categoryColumn {
  width: 22%;

  label {
    margin-bottom: 2px;
  }

  select {
    width: 100%;
  }

  option {
    &:hover {
      cursor: pointer;
      background-color: lightblue;
    }
  }
}
