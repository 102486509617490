.wrapper {
  margin-top: 20px;
}

.columnsWrapper {
  display: flex;
  flex-wrap: wrap;
}

.column {
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
  min-width: 210px;
}

.firstColumn {
  margin-top: 30px;
  margin-left: 20px;
  margin-right: 20px;
  margin-bottom: 30px;
  width: 120px;

   * {
    justify-content: center !important;
  }
}

.checkbox {
  padding: 0 10px 0 0 !important;
}

.formLabel {
  font-size: 1.4rem;
  font-weight: bold;
  margin-bottom: 0.5rem;
  margin-top: 1.5rem;
  display: block;
}

.colTitle {
  display: block;
  width: 100%;
  text-align: center;
  font-weight: 500;
  font-size: 16px;
  margin-bottom: 5px;
}

.list {
  height: 260px;
  overflow: auto;
  font-size: 12px;
  border: 1px solid #e8e8e8;
  margin-top: 30px;
  border-style: solid;
  border-width: 1px;
  border-radius: 4px;
  border-color: rgba(0, 0, 0, 0.23);
  font-size: 12px;

  &::-webkit-scrollbar {
    -webkit-appearance: none;
  }

  &::-webkit-scrollbar:horizontal {
    height: 11px;
  }

  &::-webkit-scrollbar:vertical {
    width: 11px;
  }

  &::-webkit-scrollbar-thumb {
    background-color: rgba(0, 0, 0, 0.25);
    border: 2px solid #fff;
    border-radius: 8px;
  }
}

.controlBtns {
  display: flex;
  justify-content: center;
  margin-top: 10px;
}

.addItemBtn {
  margin: 0 5px !important;
}

.deleteItemBtn {
  margin: 0 5px !important;
  background: #e10050 !important;

  svg {
    fill: #fff;
  }
}

.gradeGroups {
  display: flex;
  flex-wrap: wrap;
  position: relative;
  z-index: 10;

  > * {
    min-width: 200px;
    margin-right: 20px;
  }
}

.radioGroup {
  flex-direction: row !important;
}

.selectedItem {
  color: #fff !important;
}
