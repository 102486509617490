.bottomWrapper {
  display: flex;
  margin-bottom: 20px;
}

.filtersWrapper {
  display: flex;
  flex-direction: column;
}

.inputGroup {
  width: 400px;
  display: inline-flex;
  align-items: center;
  justify-content: space-between;

  &:not(:last-child) {
    margin-bottom: 10px;
  }

  label {
    min-width: 120px;
    padding-right: 20px;
    font-weight: 500;
    margin-left: 10px;
  }

  input,
  select {
    padding: 2px 5px;
    width: 320px;
  }
}

.children {
  flex-grow: 1;

  button {
    padding: 3px 5px;
    min-width: 230px;
    margin-left: 10px;
    text-align: center;
    cursor: pointer;
    background-color: rgba(255, 255, 255, 0.3);
    border: 1px solid rgba(0, 0, 0, 0.4);
    border-radius: 5px;

    &:hover:not(:disabled) {
      background-color: lightblue;
    }
  }
}
