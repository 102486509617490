.overlay {
  position: fixed;
  z-index: 1;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  background-color: rgba(0, 0, 0, 0.5);
}

.settingsOuter {
  position: absolute;
  top: 43%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.settingsInner {
  background-color: #fff;
  width: 90vmin;
  max-width: 800px;
  max-height: 82vmin;
  min-height: 60vmin;
  position: relative;

  display: flex;
  flex-direction: column;
}

.themeImgWrapper,
.characterImgWrapper {
  width: 90%;
  margin-left: auto;
  margin-right: auto;
  display: block;
  background-repeat: no-repeat;
  background-size: contain;
  background-position: center;
  transition: transform 0.2s ease-out;
  transform: scale(0.92);
  backface-visibility: hidden;

  &:hover {
    transform: scale(1);
  }
}

.themeImgWrapper {
  height: 25vmin;
}

.characterImgWrapper {
  height: 25vmin;
}

.itemButton {
  display: block;
  width: calc(100% - 6px);
  padding: 0 3px;
  border-radius: 5px;
  overflow: hidden;
  border: 2px solid transparent;
  transition: all 0.2s ease-out;
}

.selectedItem {
  border-color: #972bc9;
  background-color: rgba(#972bc9, 0.5);

  .themeImgWrapper,
  .characterImgWrapper {
    transform: scale(1);
    cursor: default;
  }
}

.bottom {
  margin-top: auto;
  display: flex;
  align-items: center;
  justify-content: flex-end;
  // padding-bottom: 1.5vmin;
}

.settingsActions {
  display: flex;
  align-items: center;

  button + button {
    margin-left: 1vmin;
  }
}

.phone {
  align-self: flex-end;
  margin-left: 2vmin;
  margin-right: auto;
  font-size: 2vmin;
  flex-shrink: 0;
}

// POPUP
.popup {
  position: absolute;
  background-color: #fff;
  left: 0;
  top: 0;
  right: 0;
  bottom: 0;
  z-index: 10;

  padding: 1.5vmin;
  display: flex;
  flex-direction: column;
}

.popupViewport {
  overflow-y: auto;
  flex-grow: 1;
}

.popupActions {
  align-self: flex-end;
  margin-bottom: 1.2vmin;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;

  button {
    margin-top: 1vmin;
    margin-right: 0;
  }

  button + button {
    margin-top: 1vmin;
  }
}
