.wrapper {
  display: flex;
  flex-grow: 1;
}

.list {
  margin: 0;
  // padding: 0.5vmin;
  padding: 5px;
  // border-right: 0.5vmin solid #eee;
  border-right: 5px solid #eee;
  width: 50%;
  flex-shrink: 0;
  list-style: none;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  li {
    width: 24.5%;
    height: 32%;
    display: flex;
    align-items: center;
    justify-content: center;
  }

  button {
    cursor: pointer;
    width: 100%;
    height: 100%;
    text-align: center;
  }

  img {
    max-width: 100%;
    max-height: 100%;
  }
}

.currentImageWrapper {
  flex-grow: 1;
  height: 100%;
  position: relative;

  img {
    max-width: 100%;
    max-height: 100%;
    position: absolute;
    left: 50%;
    top: 50%;
    transform: translate(-50%, -50%);
  }
}

.removeImageBtn {
  position: absolute;
  right: 5px;
  top: 0;
  color: red;
  line-height: 1;
  font-size: 40px;
  text-shadow: 1px 1px 5px rgba(0, 0, 0, 0.3);
}
