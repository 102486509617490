.custom-editor {
  width: 100%;
  height: 100%;

  &.overflowHidden {
    overflow-y: hidden;
    
    .ql-container {
      overflow-y: hidden;
    }
  }

  .ql-editor {
    font-size: 14px;
    font-family: Arial;
    height: auto;
  }

  .ql-container {
    overflow-y: scroll;
  }

  .ql-picker-options {
    max-height: 150px;
    overflow-y: auto;
  }

  .ql-snow .ql-picker.ql-font {
    width: 150px;
  }

  img {
    pointer-events: visible; // Need for quill-image-resize-module
  }
}
