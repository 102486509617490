.wrapper {
  position: absolute;
  top: 2%;
  left: 50%;
  top: 43.5vmin;
  height: 75vmin;
  width: 80vmin;
  transform: translate(-50%, -50%);
  display: flex;
}

.innerWrapper {
  flex-grow: 1;

  display: flex;
  flex-direction: column;
  overflow: hidden;
}

.title {
  font-family: Impact, sans-serif;
  font-size: 3.8vmin;
  text-align: center;
  margin-top: 0;
  margin-bottom: 3vmin;
}

.itemsList {
  list-style: none;
  padding: 0;
  margin: 0;
  flex-grow: 1;
  overflow-y: scroll;
}

.item {
  padding-top: 0.5vmin;
  padding-bottom: 0.5vmin;
  border-bottom: 1px solid #000;
  font-size: 2.5vmin;
  width: 100%;
  overflow: hidden;
  color: #000;
  user-select: none;
  text-align: left;
  position: relative;
  cursor: default;
  display: flex;
  align-items: center;

  text-decoration: none;

  &.clickable {
    cursor: pointer;

    &:hover {
      background-color: rgba(#0080ff, 0.3);
    }
  }

  &.previous {
    background-color: rgba(#0080ff, 0.3);
  }

  img {
    height: 3vmin;
    margin-right: 1vmin;
  }
}

.itemStar {
  margin-right: 1vmin;
  position: relative;

  .itemStarFill {
    position: absolute;
    top: 0;
    left: 0;
    z-index: 0;
  }

  .itemStarStroke {
    position: relative;
    z-index: 1;
  }

  &.partial .itemStarFill {
    color: yellow;
  }

  &.mastered .itemStarFill {
    color: limegreen;
  }
}

.itemIcon {
  width: 3vmin;
  height: 2.5vmin;
  margin-right: 1vmin;
  text-align: center;

  img {
    max-height: 100%;
  }
}

.itemActivity {
}

.itemGroup {
}
