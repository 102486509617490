.grid {
  display: grid;
  grid-template-columns: auto auto;
  list-style: none;
  column-gap: 1em;
  row-gap: 1em;
  align-items: center;

  & li {
    cursor: pointer;
    color: black !important;
  }
}

.fontWrapper {
  font-family: 'Roboto';
  font-weight: initial;
}

.modal {
  min-width: 65vmin;
}
