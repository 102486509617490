.form {
  flex-grow: 1;
  width: 100%;
  height: 100%;
  display: flex;
  flex-direction: column;
}

.formViewport {
  overflow-y: auto;
  height: 100%;
  border: 1px solid lightgrey;
}

.formButtonsWrapper {
  margin-top: 2vmin;
  display: flex;
  align-items: center;
  justify-content: flex-end;

  button {
    margin-right: 1vmin;
  }
}

.familyFields {
  padding: 2vmin;
  padding-bottom: 0.5vmin;
}

.familyFields:not(:first-child) {
  padding-top: 0;
}

.memberFields {
  padding: 2vmin;
  padding-bottom: 0.5vmin;

  &:nth-child(2n + 2) {
    background-color: #e8e8e8;
  }

  p {
    margin-top: 0;
  }

  @media only screen and (min-width: 576px) {
    display: flex;
    flex-wrap: wrap;
    justify-content: space-between;
  }

  > * {
    @media only screen and (min-width: 576px) {
      width: 47%;
    }

    @media only screen and (min-width: 768px) {
      &:nth-child(1),
      &:nth-child(2) {
        width: 49%;
      }

      &:nth-child(3),
      &:nth-child(4),
      &:nth-child(5) {
        width: 32%;
      }
    }
  }
}
