.wrapper {
  display: flex;
  width: 800px;
  min-height: 620px;
  flex-wrap: wrap;
  
  > * {
    width: calc(33.33% - 10px);
    margin-left: 5px;
    margin-right: 5px;
  }
}

.addButton {
  height: 240px;
  margin-bottom: 10px;
  font-weight: 500;
  font-size: 100px;

  &:hover {
    color: #0080FF;
  }
}