.timer {
  // position: absolute;
  // left: 50%;
  // top: 50%;
  // margin-top: -49vmin;
  // transform: translateX(-50%);
  position: relative;

  background-color: rgba(#fff, 0.6);
  height: 5vmin;
  width: 20vmin;
  border: 1px solid #777;
  padding: 0 0.8vmin;
}

.timerInner {
  padding: 0 0.8vmin;
  background-color: #fff;
  height: 100%;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;

  button {
    font-size: 0;
    height: 90%;
    position: absolute;
    left: 1vmin;
    top: 50%;
    transform: translateY(-50%);

    img {
      max-width: none;
      height: 100%;
      width: auto;
      transition: all 0.2s ease;
    }

    &:hover img {
      transform: scale(0.95);
    }

    &:active img {
      transform: scale(0.85);
    }
  }

  span {
    font-size: 2.5vmin;
    font-weight: 500;
  }
}
