.item {
  width: 100%;
  cursor: pointer;
  opacity: 1;
  user-select: none;
  position: relative;

  display: flex;
  align-items: center;
}

.itemText {
  display: inline-block;
}

.textValue {
  font-size: 0.75em;

  &.medium {
    font-size: 0.65em !important;
  }

  &.long {
    font-size: 0.5em !important;
  }
}

.withAudio {
  // padding-left: 3vw;
}

.playAudioBtnSmall {
  width: 4vmin;
  height: 4vmin;
  margin-right: 0.5vmin;
  flex-shrink: 0;
}
