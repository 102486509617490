.wrapper {
  padding: 10px 15px;
  border-radius: 5px;
  background: #eee;
  height: 300px;
  margin-bottom: 10px;
  position: relative;
}

.removeBtn {
  position: absolute;
  right: 5px;
  top: 5px;
  font-size: 20px;

  &:hover {
    color: lightblue;
  }
}