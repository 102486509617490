.wrapper {
  top: 2%;
  position: absolute;
  z-index: 2;
  left: 50%;
  display: flex;
  justify-content: center;
  transform: translateX(-50%);
  // max-width: 80%;
  width: 85vmin;

  text-align: center;
}

.text {
  user-select: none;
  color: #000;
  font-size: 2.2vmin;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  margin: 0;
}
