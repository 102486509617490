.changeLangButton {
  position: absolute;
  top: 5px;
  right: 5px;
  z-index: 1;
  background-color: rgba(0, 0, 0, 0.4);
  color: #fff;
  border-radius: 7px;
  padding: 7px 10px;
  transition: background-color 0.2s ease;

  &:hover {
    background-color: rgba(0, 0, 0, 0.5);
  }
}

.wrapper {
  video::-webkit-media-controls-fullscreen-button {
    display: none !important;
  }
}
