.wrapper {
  display: flex;

  &.vertical {
    flex-direction: column;
    .audioWrapper {
      margin-top: 10px;
    }
  }
}

.label {
  display: block;
  font-weight: 500;
  padding-right: 2rem;
}

.textareaWrapper {
  position: relative;
  display: flex;
  flex-direction: column;
  margin-right: 20px;
}

.audioWrapper {
  margin: 0;
}

.textarea {
  width: 160px;
  height: 60px;
}

.deleteBtn {
  position: absolute;
  top: 0;
  right: 0;
  width: 2rem;
  height: 2rem;
  // flex-shrink: 0;
}
