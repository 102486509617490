.outerClass {
    // position: relative;
    display: flex;
  }
  
  .innerClass {
    flex-grow: 1;
    display: flex;
    justify-content: space-between;
    padding: 0 !important;
  
    > * {
      width: 7.5vmin;
      height: 7.5vmin;
  
      button {
        background-size: 100% 100%;
        background-repeat: no-repeat;
        width: 100%;
        height: inherit;
        border: none;
        background-color: transparent;
        cursor: pointer;
        transform: scale(0.98);
        transition: all 0.1s;
  
        &:focus {
          outline: none;
          transform: scale(1);
        }
  
        &:hover:not(:disabled) {
          transform: scale(1);
        }
  
        &:active {
          transform: scale(0.98);
        }
  
        &:disabled {
          cursor: default;
          opacity: 0.8;
          filter: grayscale(1);
        }
      }
    }
  }
  