.label {
  // font-size: 14px;
  font-weight: 500;
  margin-top: 0;
  margin-bottom: 5px;
}

.itemsGroup {
  display: flex;
  list-style: none;
  padding-left: 0;
  margin-top: 5px;
  margin-left: -10px;
  margin-right: -10px;
}

.slot {
  position: relative;
  background-color: white;
  margin-left: 10px;
  margin-right: 10px;
  border: 1px solid rgba(0, 0, 0, 0.4);
  overflow: hidden;
  width: 80px;

  &.active {
    border-color: red;
    box-shadow: 0 0 3px 0 rgba(red, 0.8);
  }
}

.audioSmall {
  position: absolute;
  top: 1px;
  right: 1px;
  width: 25px;
  height: 25px;
}

.audioBig {
  img {
    display: block;
  }
}

.slotInner {
  height: 80px;
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  cursor: pointer;
}

.textValue {
  font-size: 12px;
  width: 100%;
  text-align: center;
  hyphens: auto;

  &.upTo2 {
    font-size: 38px;
  }
  &.upTo4 {
    font-size: 20px;
  }
  &.upTo6 {
    font-size: 14px;
  }
  &.upTo10 {
    font-size: 12px;
  }
}

.feedbackMark {
  position: absolute;
  bottom: 1px;
  right: 1px;
  font-size: 12px;
  font-weight:bold;
  > * {
    margin: 0 2px;
  }
}

.deleteBtn {
  position: absolute;
  top: 1px;
  right: 1px;

  img {
    width: 15px;
    height: 15px;
  }
}
