.wrapperOuter {
  position: absolute;
  z-index: 1;
  top: 49vh;
  left: 50%;
  transform: translate(-48vmin, -50%);

  @media only screen and (min-width: 1200px) {
    transform: translate(-38vmin, -50%);
  }
}

.stroke {
  position: absolute;
  z-index: -1;

  &.horizontal {
  }

  &.vertical {
    transform: rotate(90deg);
    transform-origin: left 0;
  }

  &.diagonal {
    transform: rotate(45deg);
    transform-origin: left center;
  }

  &::after {
    content: "";
    position: absolute;
    border-radius: 10vmin;
    border: 0.2vmin solid limegreen;
    width: 100%;
    height: 100%;
    top: 0;
    left: 0;
    animation: stroke-appear 0.2s ease-out forwards;
  }
}

@keyframes stroke-appear {
  0% {
    opacity: 0;
    transform: scale(2);
  }

  100% {
    opacity: 1;
    transform: scale(1.02);
  }
}

.grid {
  z-index: 1;
  position: relative;
}

.row {
  display: flex;
}

.letterButton {
  display: flex;
  align-items: center;
  justify-content: center;
  user-select: none;
  position: relative;
  transition: all 0.15s ease;
  line-height: 1;
  color: inherit;

  &::after {
    content: "";
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%) scale(0);
    width: 145%;
    height: 145%;
    width: 120%;
    height: 120%;
    border-radius: 50%;
    transition: all 0.15s ease;
  }

  &.selected {
    // transform: scale(1.2);
  }

  &.hinted::after {
    background-color: rgba(grey, 0.2);
    transform: translate(-50%, -50%) scale(1);
  }

  &.selected::after {
    transform: translate(-50%, -50%) scale(1.1);
    background-color: rgba(#0080ff, 0.2);
  }

  &.resolved {
    color: limegreen;
  }
}
