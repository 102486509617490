@import '../../../components/flink-play/MultiplayerOverlay/MultiplayerOverlay.module.scss';

.textAnswersBlock {
  position: absolute;
  top: 35%;
  width: 90%;
  height: 30%;
  margin: 0 5%;
  z-index: 10;

  &.multiplayer {
    width: calc(100% - 5% - #{$overlay-width});
    margin-right: #{$overlay-width};
  }
}

.longAnswersBlock {
  position: absolute;
  top: 22%;
  width: 90%;
  height: 63%;
  margin: 0 5%;
  z-index: 10;

  &.multiplayer {
    width: calc(100% - 5% - #{$overlay-width});
    margin-right: #{$overlay-width};
  }

  > * {
    width: 21% !important;
  }
}

.imagesAnswersBlock {
  position: absolute;
  top: 30%;
  width: 90%;
  height: 45%;
  margin: 0 5%;
  z-index: 10;

  &.multiplayer {
    width: calc(100% - 5% - #{$overlay-width});
    margin-right: #{$overlay-width};
  }
}

.textAnswerWrapper {
  width: 18%;
  position: absolute;
  pointer-events: all !important;

  .answerBtn {
    hyphens: auto;
    background-color: rgba(255, 255, 255, 0.85);
    text-align: center;
    border: 1px solid #000000;
    box-shadow: 0px 0px 7px #144b66;
    cursor: pointer;
    border-radius: 13px;
    color: #000;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 6.6%;
    transform: translateX(-50%);
    animation-duration: inherit;
    animation-delay: inherit;

    &:hover,
    &:focus {
      background-color: rgba(255, 255, 255, 1);
      outline: none;
    }

    &:disabled {
      cursor: default;
    }
  }
}

.imageAnswerWrapper {
  width: 15%;
  position: absolute;
  height: 40%;
  pointer-events: all !important;

  .answerBtn {
    background-color: #fff;
    border: 1px solid #000000;
    box-shadow: 0px 0px 7px #144b66;
    cursor: pointer;
    border-radius: 13px;
    overflow: hidden;
    width: 100%;
    height: 100%;
    padding: 6.6%;
    transform: translateX(-50%);
    transition: box-shadow 0.2s ease;
    display: flex;
    align-items: center;
    justify-content: center;
    animation-duration: inherit;
    animation-delay: inherit;

    &:hover,
    &:focus {
      box-shadow: 0px 0px 15px rgba(#000, 0.8);
    }

    &:disabled {
      cursor: default;
    }

    img {
      max-width: 95%;
      max-height: 95%;
    }
  }
}

.keepGoingWrapper {
  animation-timing-function: linear;
  animation-iteration-count: infinite;
}

.playAudioBtn {
  position: absolute;
  right: 50%;
  transform: translateX(105%);
  bottom: 0;
  animation-duration: inherit;
  animation-delay: inherit;

  img {
    max-width: 40px;
    max-height: 40px;
  }
}

.horizontalKeepGoingBlock {
  pointer-events: none;
  position: absolute;
  top: 22%;
  width: 100%;
  height: 63%;
  z-index: 10;

  &.multiplayer {
    width: calc(100% - #{$overlay-width});
    margin-right: #{$overlay-width};
    overflow: hidden;
  }

  .answerBtn {
    transform: translate(0, -50%);
  }

  .imageAnswerWrapper {
    height: 30%;

    .answerBtn {
      transform: translate(0, 0);
    }
  }
}

.verticalKeepGoingBlock {
  pointer-events: none;
  position: absolute;
  top: 0;
  bottom: 0;
  width: 90%;
  margin: 0 5%;
  height: 100%;
  z-index: 1;

  &.multiplayer {
    width: calc(100% - 5% - #{$overlay-width});
    margin-left: 5%;
    margin-right: #{$overlay-width};
  }

  .imageAnswerWrapper {
    height: 22%;
  }

  .answerBtn {
    transform: translate(-50%);
  }
}

.fadeIn {
  opacity: 0;
  animation-name: fadeIn;
  animation-fill-mode: forwards;
}

.fadeOut {
  opacity: 1;
  animation-name: fadeOut;
  animation-fill-mode: forwards;
}

.pausedAnimation {
  animation-play-state: paused;
}

@keyframes fadeIn {
  0% {
    opacity: 0;
  }
  100% {
    opacity: 1;
  }
}

@keyframes fadeOut {
  0% {
    opacity: 1;
  }
  100% {
    opacity: 0;
  }
}

.leftToRight {
  animation-name: leftToRight;
}

@keyframes leftToRight {
  0% {
    left: 0%;
    transform: translate(-100%, 0);
  }

  100% {
    left: 100%;
    transform: translate(0, 0);
  }
}

.rightToLeft {
  animation-name: rightToLeft;
}

@keyframes rightToLeft {
  0% {
    right: 0;
    transform: translate(100%, 0);
  }

  100% {
    right: 100%;
    transform: translate(0, 0);
  }
}

.topToBottom {
  animation-name: topToBottom;
}

@keyframes topToBottom {
  0% {
    top: 0%;
    transform: translate(0, -100%);
  }

  100% {
    top: 100%;
    transform: translate(0, 0);
  }
}

.bottomToTop {
  animation-name: bottomToTop;
}

@keyframes bottomToTop {
  0% {
    bottom: 0%;
    transform: translate(0, 100%);
  }

  100% {
    bottom: 100%;
    transform: translate(0, 0);
  }
}
