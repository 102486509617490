.wrapper {
  display: flex;
  padding-bottom: 10px;
  // border-bottom: 1px solid rgba(0, 0, 0, 0.3);
}

.textareaWrapper {
  display: block;
  width: 60%;
  margin-right: 15px;
}

.label {
  display: block;
  font-weight: 500;
  // font-size: 14px;
}

.textarea {
  display: block;
  height: 70px;
  width: 100%;
  padding: 5px 10px;
}
