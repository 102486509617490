#story-studio-editor {
  flex-grow: 1;
  color: #000;
  font-family: sans-serif;
  display: flex;
  flex-direction: column;
  max-width: 100%;
  max-height: 100%;
  font-size: 18px;

  small {
    font-size: 60%;
    display: block;
  }

  h1 {
    font-size: 48px;
  }

  h2 {
    font-size: 36px;
  }

  h3 {
    font-size: 30px;
  }

  h1,
  h2,
  h3 {
    margin: 0;
    padding: 0;
    font-weight: normal;
  }

  .ql-toolbar {
    padding: 0;
    border: none;
    border-bottom: 1px solid #000;
    user-select: none;
    padding-bottom: 10px;
    margin-bottom: 10px;
    flex-shrink: 0;

    button {
      margin: 0 0.2px;
      width: 24px;
      height: 24px;
      padding: 0;
      line-height: 1;
    }

    .ql-clean {
      margin-left: 20px;
    }

    .writing-feedback button {
      width: max-content !important;
      height: auto !important;

      img, span {
        vertical-align: middle;
        display: inline-block;
        font-family: Impact, Haettenschweiler, 'Arial Narrow Bold', sans-serif;
      }

      img {
        padding-right: 5px;
      }

      &:disabled img { 
        pointer-events: none !important;
      }

      &:disabled {
        cursor: default;
        opacity: 0.8;
        filter: grayscale(1);
        pointer-events: none;
      }
    }

  }

  .quill {
    flex-grow: 1;
    overflow-y: auto;
    hyphens: auto;
  }

  .ql-editor {
    overflow: hidden;
  }

  .ql-container {
    font: inherit;
    border: none;
  }

  .ql-formats {
    margin-right: 0;
  }

  .ql-editor {
    padding: 0;
    font: inherit;
  }

  .ql-snow .ql-picker.ql-font {
    width: 160px;
    height: auto;
    font: inherit;
  }
}

.story-studio-editor {
  &__toolbar-top {
    display: flex;
    align-items: center;
    justify-content: center;
  }

  &__size-controls {
    list-style: none;
    padding: 0;
    margin: 0;
    line-height: 1;

    li {
      display: inline-block;
      margin: 5px;
      cursor: pointer;
    }
  }

  &__color-controls {
    list-style: none;
    padding: 0;
    margin: 0;

    li {
      display: inline-block;
      margin: 5px;
      cursor: pointer;
      width: 30px;
      height: 30px;
      border-radius: 50%;
      border: 4px solid #ccc;
    }
  }

  &__words {
    display: flex;
    justify-content: center;
    flex-wrap: wrap;
    padding: 0;
    margin: 0;
    list-style: none;

    li {
      cursor: pointer;
      margin: 3px;
      padding: 0 3px;
      border: 2px dashed #c77b6c;
      border-radius: 10px;
      transition: all 0.2s ease;

      &:hover {
        background-color: #c77b6c;
        color: #fff;
      }
    }
  }
}
