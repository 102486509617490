.audioString {
  display: flex;
  flex-direction: row;

  gap: 0.5em;
  align-items: center;

  & button {
    height: 3vmin;
    width: 3vmin;

    & img {
      height: 100%;
      width: 100%;
    }
  }
}
