// .wrapper {
//   display: block;
//   position: absolute;
//   left: 50%;

//   top: calc(50% - 6.5vmin); // 6.5vmin - it's half of global button space (13vmin)
//   left: 50%;

//   background-color: red;
// }

.topOuter {
  position: absolute;
  top: 3vmin;
  left: 50%;
  transform: translateX(-50%);
  width: 90vmin;
  height: 10vmin;
}

.topInner {
  display: flex;
  justify-content: space-evenly;
  align-items: center;
  padding: 0;
  position: relative;

  font-size: 2.4vmin;

  > * {
    margin: 0 0.5vmin;
  }
}

.mainOuter {
  position: absolute;
  left: 50%;
  top: calc(
    50% - 6.5vmin + 6.5vmin
  ); // 6.5 half of global buttons, 6.5 half of top block
  transform: translate(-50%, -50%);
}

.mainInner {
  font-size: 3vmin;
  display: flex;
  justify-content: space-evenly;
}

.answersCol {
  display: flex;
  flex-direction: column;
}

.button {
  color: black;
  background: none;
  outline: none;
  padding: 2vmin;
  margin: 2vmin 4vmin;
  border-width: 3px;
  border-style: solid;
  border-color: transparent;
  border-radius: 1vmin;

  &:hover {
    color: var(--blue);
  }

  &.selected {
    border-color: var(--blue);
  }

  &.selected.wrong {
    animation: wrong-blink 0.25s backwards;
    border-color: red;
  }

  &.selected.correct {
    border-color: limegreen;
  }
}

@keyframes wrong-blink {
  25% {
    border-color: transparent;
  }
  50% {
    border-color: red;
  }
  75% {
    border-color: transparent;
  }
}
