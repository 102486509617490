.wrapper {
  min-height: 100vh;
  max-width: 992px;
  margin: 0 auto;
  padding: 50px 20px;
}

.header {
  margin-bottom: 40px;
}

.headerTop {
  display: flex;
  align-items: flex-start;
  justify-content: space-between;

  select {
    width: 100px;
  }
}

.logoWrapper {
  width: 150px;
}

.main {
  font-size: 12px;

  p {
    margin-bottom: 30px;
  }

  @media only screen and (min-width: 768px) {
    font-size: 14px;
  }

  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }

  @media only screen and (min-width: 1366px) {
    font-size: 18px;
  }

  a {
    color: #0080ff;

    &:hover {
      color: darken(#0080ff, 15%);
    }
  }
}
