.wrapper {
  min-height: 100vh;
  max-width: 992px;
  margin: 0 auto;
  padding: 50px 20px;
}

.spaceBetween {
  display: flex;
  justify-content: space-between;
}

.header {
  margin-bottom: 40px;

  @media only screen and (min-width: 768px) {
    margin-bottom: 60px;
  }

  @media only screen and (min-width: 992px) {
    margin-bottom: 80px;
  }
}

.logoWrapper {
  width: 150px;
  margin: 0 auto 20px;

  @media only screen and (min-width: 768px) {
    margin: 0;
    margin-bottom: 40px;
  }
}

.langSelect {
  height: min-content;
}

.pageTitle {
  text-align: center;
  font-weight: 500;
  font-size: 20px;

  @media only screen and (min-width: 768px) {
    font-size: 24px;
  }

  @media only screen and (min-width: 992px) {
    font-size: 28px;
  }

  @media only screen and (min-width: 1366px) {
    font-size: 36px;
  }
}

.main {
  font-size: 12px;

  p {
    margin-bottom: 30px;
  }

  @media only screen and (min-width: 768px) {
    font-size: 14px;
  }

  @media only screen and (min-width: 992px) {
    font-size: 16px;
  }

  @media only screen and (min-width: 1366px) {
    font-size: 18px;
  }

  a {
    color: #0080ff;

    &:hover {
      color: darken(#0080ff, 15%);
    }
  }
}

.form {
  margin: 50px auto 0;
  position: relative;
  display: flex;
  font-size: 14px;
  width: 260px;
  position: relative;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
    width: 360px;
  }

  input {
    width: 100%;
    border: 1px solid var(--blue);
    background-color: #fff;
    font: inherit;
    font-size: 1;
    line-height: 1;
    filter: none;
    border-radius: 26px 0 0 26px;
    padding: 10px 12px 12px;
    
    @media only screen and (min-width: 768px) {
      padding: 14px 20px 16px;
    }

    &:focus {
      outline: none;
      border: 1px solid var(--purple);
    }
  }

  button[type='submit'] {
    background: var(--blue);
    color: #fff;
    border-radius: 0 26px 26px 0;
    font-weight: 500;
    padding: 10px 16px 12px 11px;
    
    @media only screen and (min-width: 768px) {
      padding: 14px 20px 16px 14px;
    }
    
    &:hover {
      background-color: var(--purple);
    }
  }
}

.registerBtnWrapper {
  text-align: center;
  margin-top: 60px;
}

.registerBtn {
  color: rgb(61, 61, 233);

  &:hover {
    text-decoration: underline;
  }
}

.formError {
  padding: 0;
  margin: 0;
  margin-top: 5px;
  position: absolute;
  top: 100%;
  left: 16px;
  right: 16px;
  color: red;
  font-size: 80%;
}
