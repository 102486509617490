.wrapper {
  animation: blink 1.5s infinite;
  color: red;
  margin-bottom: 5px;
}

@keyframes blink {
  0% {
    opacity: 1;
  }
  50% {
    opacity: 1;
  }
  80% {
    opacity: 0;
  }
}
