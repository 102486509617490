.column {
    display: flex;
    flex-direction: column;
    gap: 1vmin;
}

.record {
    display: flex;
    flex-direction: row;

    justify-content: space-between;
    align-items: center;

    padding: 1vmin;

    background-color: lighten(gray, 35);
    border-radius: 1.5vmin;
    min-height: 5vmin;

    .description {
        font-size: 1.75vmin;
        min-width: 10vmin;
        max-width: 10vmin;
        word-wrap: break-word;
    }

    .colored {
        font-weight: bold;
        // opacity: 0.8;

        border-radius: 0.5vmin;
        padding: 0.5vmin;

        min-height: 3.5vmin;
        min-width: 8.5vmin;

        color: #fff;

        text-align: center;
        justify-content: center;
        align-items: center;

        &.negative { 
            background-color: var(--blue);
        }
        &.positive {
            background-color: var(--purple);
        }
    }

}