.positionedBox {
  top: 72%;
  position: absolute;
  left: 20%;
  width: 60%;
  display: flex;
  justify-content: center;
}

.wrapper {
  width: 100%;
  text-align: center;
}

.text {
  user-select: none;
  color: #000;
  font-size: 2.4vmin;
  font-family: Roboto, sans-serif;
  font-weight: 500;
  margin: 0;
}

