.wrapper {
  display: flex;
}

.btn {
  width: 50%;
  text-align: center;
  cursor: pointer;
  font-weight: 500;
  padding-bottom: 5px;

  img {
    width: 135px;
    display: block;
    margin: 0 auto 10px;
  }

  input {
    display: none;
  }
}