.wrapper {
  position: absolute;
  bottom: 32%;
  left: 50%;
  transform: translateX(-50%);

  background-size: 100% 100%;
  width: 90vmin;
  height: 35vmin;
  padding: 1vmin;

  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
}

.keysRow {
  height: 23%;
  align-items: center;
  display: flex;
}

.nextToSpace {
  align-items: center;
  display: flex;
  width: 50%;
}

.key {
  display: flex;
  align-items: center;
  justify-content: center;

  color: #fff;
  font-weight: 500;
  font-family: 'Roboto', sans-serif;
  font-size: 2.6vmin;

  background-size: 100% 100%;
  width: 5.5vmin;
  height: 5.5vmin;
  flex-shrink: 0;
  margin: 0 1vmin;
  transition: all 0.2s ease;

  img {
    max-height: 60%;
    max-width: 60%;
  }

  &.--shift {
    width: 10vmin;
  }

  &.--delete {
    width: 10vmin;
  }

  &.--space {
    width: 20vmin;
    flex-shrink: 0;
  }
}

.wrapper:not(.disabled) {
  .key {
    &:hover {
      transform: scale(1.05);
    }

    &:focus:active {
      transform: scale(0.95);
    }
  }
}
