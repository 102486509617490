.ebookWrapper {
  position: absolute;
  left: 50%;
  top: 50%;
  transform: translate(-50%, -55%);
  width: auto;
}

.ebook {
  position: relative;
  background-color: #fff;
  position: relative;
  // width: 95vmin;
  // height: 61.25vmin;
  user-select: none;

  display: flex;
  align-items: center;
  justify-content: center;
}

.ebookImage {
  z-index: 0;
  max-width: 100%;
  max-height: 100%;
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
}

.text {
  position: absolute;
  hyphens: auto;
  font-size: 1.75vmin;
  font-family: Arial;
  line-height: 1.42;

  overflow-y: auto;

  * {
    margin: 0;
    padding: 0;
  }
}

.replayBtn {
  position: absolute;
  top: 50%;
  left: 50%;
  transform: translate(-50%, -50%);
  z-index: 11;
  height: auto;
  width: 7%;
  transition: all 0.3s ease;
  animation: appear 1s ease;
  transform: scale(0.9);

  &:hover {
    transform: scale(1);
  }
}

@keyframes appear {
  0% {
    transform: scale(0);
  }

  100% {
    transform: scale(0.9);
  }
}

.navBtn {
  position: absolute;
  top: 0;
  bottom: 0;
  width: calc(50% + 6.5vmin);
  display: flex;
  align-items: center;
  user-select: none;
  z-index: 10;

  @media (orientation: landscape) {
    width: calc(50% + 6.5vmin);
  }

  @media (orientation: portrait) {
    width: 50%;
  }

  &:hover img {
    transform: scale(0.95);
  }

  &:active img {
    transform: scale(0.85);
  }

  &.prevBtn {
    justify-content: flex-start;

    @media (orientation: landscape) {
      left: -6.5vmin;
    }

    @media (orientation: portrait) {
      left: 0;
    }
  }

  &.nextBtn {
    justify-content: flex-end;

    @media (orientation: landscape) {
      right: -6.5vmin;
    }

    @media (orientation: portrait) {
      right: 0;
    }
  }

  img {
    width: 5vmin;
    transition: all 0.2s ease;
  }
}
