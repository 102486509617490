.wrapper {
  font-weight: 400;
  text-align: center;
  font-size: 14px;
  width: 50vmin;
  display: flex;
  flex-direction: column;
  align-items: center;

  @media only screen and (min-width: 768px) {
    font-size: 16px;
  }

  @media only screen and (min-width: 992px) {
    font-size: 20px;
  }

  @media only screen and (min-width: 1200px) {
    font-size: 26px;
  }

  p {
    margin: 2px 0;
  }
}

.imageButtons {
  margin-top: 3vmin;
  align-items: center;
  justify-content: center;
  display: flex;

  img {
    display: block;
    width: 7vmin;
    height: 7vmin;
    margin-bottom: 1rem;
  }

  button {
    margin: 0 2vmin;
    transition: transform 0.2s;
    display: flex;
    flex-direction: column;
    align-items: center;
    font-size: 2vmin;
    transform: scale(0.9);
    
    &:active {
      transform: scale(0.95);
    }

    &:hover {
      transform: scale(1);
    }
  }
}

.title {
  display: flex;
  align-items: center;
  justify-content: center;
  font-size: 3vmin;
  font-weight: 500;
  margin-bottom: 2vmin;

  img {
    height: 7vmin;
    margin-bottom: 0.3vmin;
    margin-left: 0.5vmin;
  }
}

.activityScoreProgress {
  display: flex;
  flex-wrap: wrap;
  justify-content: center;
  max-width: 80%;
  border: 1px solid #000;
  padding: 0 0.8vmin;
  margin: 0 auto 2vmin;

  span {
    height: 2.5vmin;
    width: 2.5vmin;
    margin: 0.6vmin;
    border-radius: 50%;
    border: 1px solid #000;
    background-color: #fff;

    &.progressFilled {
      background-color: green;
    }
  }
}

.stats {
  display: block;
}
