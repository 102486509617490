@font-face {
  font-family: 'Times New Roman Navajo';
  src: local('Times New Roman Navajo'), url(../fonts/times-new-roman-navajo.ttf) format('truetype');
}

html {
  box-sizing: border-box;
  font-size: 62.5%;
}

:root {
  --blue: #0080c1;
  --purple: #8a2380;
  --font-base: 'Roboto', sans-serif;

  --font-din: 'Times New Roman Navajo', sans-serif;
}

*,
*::before,
*::after {
  box-sizing: inherit;
}

body {
  font-family: var(--font-base);
  margin: 0;
  font-size: 1.4rem;
  -webkit-font-smoothing: antialiased;
}

img {
  max-width: 100%;
  height: auto;
  pointer-events: none;
}

.custom-container {
  width: 80%;
  margin: 0 auto;
}

button {
  background-color: transparent;
  border: none;
  cursor: pointer;
  padding: 0;

  &:focus {
    outline: none;
  }
}

strong {
  font-weight: bold;
}

ul {
  margin-left: 0;
  padding-left: 20px;
}

textarea {
  resize: none;
}

#root {
  position: fixed;
  top: 0;
  bottom: 0;
  right: 0;
  left: 0;
  overflow: auto;
}
