.familyPointsTitle {
    font-size: 1.4rem;
    font-weight: bold;
    margin-bottom: 5px;
}

.field {
    display: flex;
    flex-direction: row;
    align-items: center;

    label {
        min-width: 20em;
    }

    & * {
        margin-bottom: 0 !important;
    }

    margin-bottom: 5px;
}

.inputField {
    margin-right: 5px;

    /* Chrome, Safari, Edge, Opera */
    input::-webkit-outer-spin-button,
    input::-webkit-inner-spin-button {
        -webkit-appearance: none;
        appearance: none;
        margin: 0;
    }

    /* Firefox */
    input[type=number] {
        -moz-appearance: textfield;
        appearance: none;
    }
}
