.wrapper {
  // background-color: #fff;
  opacity: 1;
}

.inner {
  padding: 0;

  display: flex;
}

.button {
  display: flex;
  align-items: center;
  justify-content: center;
  position: relative;

  &[hidden] {
    display: block;
    visibility: hidden;
  }

  &:disabled img {
    opacity: 0.5;
  }

  img {
    max-width: 90%;
    max-height: 90%;
  }

  &.processing::after {
    content: '';
    position: absolute;
    top: 50%;
    left: 50%;
    transform: translate(-50%, -50%);

    padding: 37%;
    border-radius: 50%;
    border-width: 3px;
    border-style: solid;
    border-color: #0080ff transparent #0080ff transparent;
    animation: rotate 1s linear infinite;
  }
}

@keyframes rotate {
  0% {
    transform: translate(-50%, -50%) rotate(0deg);
  }

  50% {
    transform: translate(-50%, -50%) rotate(180deg);
  }

  100% {
    transform: translate(-50%, -50%) rotate(360deg);
  }
}
