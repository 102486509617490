.buttonWrapper {
  position: absolute;
  top: 2vmin;
  right: 2vmin;
}

.date {
  margin-top: 1vmin;
  display: block;
  font-weight: 500;
  font-size: 1.6vmin;
  text-align: center;
}

.button {
  font-size: 2vmin;
  border-radius: 1.5vmin;

  color: #fff;
  background-color: var(--purple);
  padding: 1vmin 3vmin;
  transition: all 0.1s;
  opacity: 0.8;

  &:hover {
    opacity: 1;
  }
}

.modalContent {
  width: 65vmin;
}

.label {
  font-size: 2vmin;
  font-weight: 500;
  display: block;
  margin-bottom: 1vmin;
}

.approveWrapper {
}

.fieldsWrapper {
  display: flex;
  justify-content: space-between;
  margin-bottom: 2vmin;
}

.radioGroup {
  display: flex;
  align-items: center;
  flex-wrap: wrap;

  label {
    font-size: 1.8vmin;
    margin-right: 2vmin;
    margin-bottom: 2vmin;
    position: relative;
    padding-left: 2vmin;

    input {
      display: none;
    }

    span {
      &::before {
        content: '';
        width: 1.5vmin;
        height: 1.5vmin;
        border: 1px solid #000;
        position: absolute;
        left: 0;
        top: 0.3vmin;
      }

      &::after {
        content: '';
        position: absolute;
        font-size: 2.4vmin;
        left: 0vmin;
        top: -0.5vmin;
      }

      &:hover {
        color: var(--blue);
        cursor: pointer;

        &::before {
          border-color: var(--blue);
        }
      }
    }

    input:checked + span {
      color: var(--blue);

      &::after {
        content: '✓';
      }

      &::before {
        border-color: var(--blue);
      }
    }
  }
}

.levelSelect {
  width: 15vmin;
  text-align: center;
}

.subjectSelect {
  width: 20vmin;
}

.calendarBlock {
  display: flex;
  flex-direction: column;

  > div {
    flex-grow: 1;
  }
}
