.data-list {
  height: 300px;
  overflow: auto;
  background-color: #fff;
  padding: 3px;
  margin: 0;

  &--small {
    height: 70px;
  }

  &--big {
    flex-grow: 1;
  }

  &__item {
    list-style: none;
    cursor: pointer;
    border-bottom: 1px solid #777;
    height: 20px;
    overflow: hidden;
    line-height: 1.5;
    font-size: 14px;
    padding-left: 20px;

    background-repeat: no-repeat;
    background-position: center left;
  }

  &__item:hover {
    background-color: #eee;
  }

  &__item--active {
    background-color: lightblue;
  }

  &__item--active:hover {
    background-color: lightblue;
  }

  &__item--without-star {
    padding-left: 5px;
    background-image: none !important;
  }
}
