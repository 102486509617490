.clipartWrapper {
  // padding: 0 20px;
  padding: 0 1vmin;
  display: flex;
  height: 100%;
  flex-direction: column;
}

.filterBlock {
  margin-bottom: 10px;
  align-items: center;
  display: flex;
  justify-content: center;
  font-size: 1.6vmin;

  p {
    margin-bottom: 0;
    margin-top: 0;
    // margin-right: 15px;
    margin-right: 0.75vmin;
  }
}

.clipartGrid {
  display: flex;
  flex-wrap: wrap;
  // max-height: 600px;
  // max-height: 50vmin;
  // width: 900px;
  margin: 0 auto;
  justify-content: flex-start;
  overflow-y: auto;
}

.clipartItem {
  padding: 2px;
  margin: 1px;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  width: calc(16.66% - 2px);
  // height: 150px;
  height: 10vmin;
  border: 2px solid #d5d5d5;

  img {
    flex-shrink: 1;
    max-height: 80%;
  }

  figcaption {
    // margin-top: 5px;
    margin-top: 0.5vmin;
    font-size: 1.6vmin;
  }
}

figure.clipartItem {
  cursor: pointer;
  &:hover {
    border: 2px solid #0080ff;
    box-shadow: 0 0 2px #0080ff;
  }
}
