.footer {
  display: flex;
  align-items: center;
  justify-content: space-between;
  margin-top: 30px;
}

.spinnerWrapper {
  display: flex;
  justify-content: center;
}