.letterSlot {
  border: 0.5vmin dashed #c77b6c;
  border-radius: 1.5vmin;
  width: 7vmin;
  height: 7vmin;
  margin: 0 0.8vmin;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: all 0.1s ease;
  animation-duration: inherit;
  animation-delay: inherit;

  @media only screen and (max-width: 1199px) {
    width: 6vmin;
    height: 6vmin;
    margin: 0 0.5vmin;
  }

  @media only screen and (orientation: portrait) and (max-width: 767px) {
    width: 5vmin;
    height: 5vmin;
    margin: 0 0.5vmin;
  }
}