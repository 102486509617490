.positionedBox {
  display: flex;
  justify-content: center;
}

.small {
  width: 55vmin;

  .inner {
    > * {
      flex-shrink: 0;
      width: auto;
      flex-grow: 1;
      min-width: 15%;
      max-width: 28%;
    }
  }
}

.big {
  width: 80vmin;

  .inner {
    > * {
      flex-shrink: 0;
      width: auto;
      flex-grow: 1;
      min-width: 12%;
      max-width: 22%;
    }
  }
}

.wrapper {
  width: 100%;
}

.inner {
  display: flex;
  flex-wrap: wrap;
  justify-content: space-around;
}

.text {
  font-size: 2.5vmin;
  text-align: center;

  &.textUpTo2 {
    font-size: 9vmin;
  }
  &.textUpTo4 {
    font-size: 5vmin;
  }
  &.textUpTo6 {
    font-size: 4vmin;
  }
  &.textUpTo10 {
    font-size: 3.5vmin;
  }
  &.textUpTo20 {
    font-size: 3vmin;
  }
}
