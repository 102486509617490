.container {
  display: flex;
  min-height: 470px;
  width: 660px;
}

.leftColumn {
  margin: 0 10px;
  width: 280px;
  display: flex;
  flex-direction: column;
}

.spacer {
  height: 1em;
}

.row {
  display: flex;
  flex-direction: row;
  margin-top: auto;
  margin-bottom: 20px;
  gap: 1em;
}

.select {
  height: min-content;
  padding: 5px 0px;
}

.rightColumn {
  margin: 0 10px;
  display: flex;
  flex-direction: column;
}

.input {
  font: inherit;
  border: 1px solid #333;
  box-shadow: none;
  outline: none;
  padding: 5px 10px;
  width: 280px;
}

.label {
  display: block;
  margin-bottom: 5px;
}

.template-btns-wrapper {
  width: 340px;
  margin-top: 20px;
  display: flex;
  flex-wrap: wrap;
  justify-content: space-between;

  > * {
    width: 48%;
  }
}

.template-btn {
  text-align: left;
  padding: 5px;

  &:hover,
  &.active {
    background-color: #d4d5d4;
  }
}

.template-descr {
  margin-top: 20px;

  img {
    width: 280px;
  }
}

.save-btn {
  border: 1px solid #0080ff;
  color: #0080ff;
  align-self: center;
  padding: 5px 40px;
  border-radius: 10px;

  &:disabled {
    color: #9b9b9b;
    border-color: #9b9b9b;
  }

  &:hover:not(:disabled) {
    color: #fff;
    background-color: #0080ff;
  }
}
