.item {
  width: 6vw;
  height: 12vh;
  // width: 24vmin;
  // height: 24vmin;

  // padding: 1vh 1vw;
  display: inline-flex;
  align-items: center;
  justify-content: center;
  cursor: pointer;
  opacity: 1;
  user-select: none;
  position: relative;
}

.itemText {
  display: inline-block;
}

.itemImg {
  display: inline-block;
}

.itemAudio {
  display: inline-block;
}

.playAudioBtn {
  position: relative;
  width: 90%;
  height: 90%;
}

.playAudioBtnSmall {
  position: absolute;
  width: 4vmin;
  height: 4vmin;
  bottom: 0;
  right: 0;

  img {
    display: block;
  }
}

.itemImg {
  width: 90%;
  height: 90%;
  object-fit: contain;
}