.wrapper {
    display: flex;
    flex-direction: column;
    text-align: center;
    align-items: center;
    width: 15vmin;
}

.title {
    font-weight: bold;
    font-size: 1.75vmin;
    margin: 0;
}

.points {
    font-size: 2vmin;
    border-radius: 1.5vmin;
    font-weight: bolder;
    font-size: 2.25vmin;

    width: 100%;
    
    color: #fff;
    background-color: var(--purple);
    padding: 1vmin 1vmin;
    transition: all 0.1s;
    opacity: 0.8;

    &:hover {
        opacity: 1;
    }
}

.reward {
    font-weight: 700;
    font-size: 1.25vmin;
    margin-bottom: 0;
    margin-top: 0.15vmin;
}
