.slot {
  display: inline-flex;

  &::after {
    content: '\a0';
    display: inline-block;
    width: 0;
    overflow: hidden;
  }

  > * {
    // display: inline-flex !important;
    color: #0080ff;
  }
}

.wrapper {
  display: flex;
  position: absolute;
  user-select: none;

  left: 52%;
  transform: translateX(-50%);
  top: 3vmin;
  width: 91vmin;
  height: 61vmin;
  z-index: 1;

  @media only screen and (min-width: 992px) {
    left: 50%;
  }
  // left: 12.7%; // 260 / 2048
  // top: 3%; // 50 / 1536
  // width: 77.6vw; // 1590 / 2048
  // height: 61vh; // 935 / 1536
}

.textBlock {
  white-space: pre-wrap;

  div {
    display: inline;
  }
}

.layout {
  width: 100%;
  display: flex;
}

.slotWrapper {
  display: inline-flex !important;
  flex-direction: column;
  text-align: center;
  position: relative;
  z-index: 1;
  line-height: 1;

  &::after {
    content: '';
    position: absolute;
    bottom: 0;
    left: 0;
    width: 100%;
    height: 1px;
    background-color: #000;
  }
}

.spacer {
  height: 0;
  overflow: hidden;
}

.textWrapper {
  overflow-y: hidden;

  // &::-webkit-scrollbar {
  //   -webkit-appearance: none;
  // }

  // &::-webkit-scrollbar:horizontal {
  //   height: 11px;
  // }

  // &::-webkit-scrollbar:vertical {
  //   width: 11px;
  // }

  // &::-webkit-scrollbar-thumb {
  //   background-color: rgba(0, 0, 0, 0.25);
  //   border: 2px solid #fff;
  //   border-radius: 8px;
  // }
}

.imageWrapper {
  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-height: 100%;
  }
}

.IMAGE_IN_TOP {
  .layout {
    flex-direction: column;
    align-items: center;
    justify-content: space-between;
  }

  .imageWrapper {
    order: 1;
    flex-shrink: 0;
    height: 35%;

    img {
      height: 100%;
      width: auto;
      max-width: none;
    }
  }

  .textWrapper {
    order: 2;
    // width: 100%;
    height: 58%;
    flex-shrink: 0;
  }
}

.IMAGE_IN_RIGHT {
  .layout {
    align-items: center;
    justify-content: space-between;
  }

  .textWrapper {
    width: 49%;
    flex-shrink: 0;
    align-self: stretch;
  }

  .imageWrapper {
    height: 100%;
    margin-left: auto;
    margin-right: auto;
  }
}

.BIG_TEXT {
  .layout {
    justify-content: center;
  }

  .textWrapper {
  }

  .imageWrapper {
    display: none;
  }
}

.SMALL_TEXT {
  width: 51.4vmin;
  height: 41.3vmin;
  left: 50%;
  top: 13vmin;

  .layout {
    justify-content: center;
  }

  .textWrapper {
  }

  .imageWrapper {
    display: none;
  }
}
