.wrapper {
  top: 22%;
  position: absolute;
  left: 50%;
  transform: translateX(-50%);
  width: 60%;

  &.large {
    width: 60%;

    .box {
      width: 25%;
      height: 25%;
    }
  }

  &.medium {
    width: 70%;

    .box {
      width: 20%;
      height: 20%;
    }
  }

  &.small {
    width: 80%;

    .box {
      width: 14%;
      height: 20%;
    }
  }
}

.innerWrapper {
  height: 45vmin;
  display: flex;
  flex-wrap: wrap;
}

.itemWrapper {
  user-select: none;
}

.box {
  display: flex;
  align-items: center;
  justify-content: center;
}
