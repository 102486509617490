.wrapper {
  position: fixed;
  top: 0;
  left: 0;
  right: 0;
  bottom: 0;
  background-color: rgba(#000, 0.5);
  z-index: 100;

  display: flex;
  align-items: center;
  justify-content: center;

  img {
    max-width: 90vw;
    max-height: 90vh;
  }
} 