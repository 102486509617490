.slot {
  border: 0.5vmin dashed #c77b6c;
  border-radius: 1.5vmin;
  width: 37%;
  height: auto;
  min-height: 6vmin;
  margin: 0 0.8vmin;
  display: inline-flex;
  align-items: center;
  padding: 0 0.5vw;
  transition: all 0.1s ease;
  animation-duration: inherit;
  animation-delay: inherit;

  > * {
    width: 100%;
  }
}

.fixedSlot {
  border: none;
}

.boardOuter {
  position: fixed;
  top: 19%;
  left: 50%;
  transform: translateX(-50%);
}

.boardInner {
  height: 62vmin;
  display: flex;
  flex-direction: column;
  width: 81vmin;
  // overflow-x: hidden;
  // overflow-y: auto;
}

.slotsWrapper {
  width: 100%;
  padding: 0;
  margin-bottom: auto;
  background: none;
}

.slotsInner {
  width: 100%;
  padding: 0;
  background: none;
}

.questionsWrapper {
  display: block;
}

.questionItemWrapper {
  margin-top: 1vmin;
  padding: 0.5vmin;
  width: 100%;
}

.answersWrapper {
  display: block;
  margin-top: auto;
  padding: 0;
  background: none;
}

.answersInner {
  display: flex;
  justify-content: space-around;
  // flex-wrap: wrap;
  align-items: center;
  padding: 0;
  background: none;
}

.boardBox {
  margin: 0 0.8vmin;
}

.slotQuestion {
  cursor: default !important;
  width: 60% !important;
  border: 0.5vmin dashed #000;
  border-radius: 1.5vmin;
  padding: 1vmin;

  > * {
    font-size: 1em !important;
  }
}

.slotWrapper {
  margin: 0.5vmin 0.5vmin 1.5vmin;
  display: flex;
  align-items: center;
  justify-content: space-between;
}
