.wrapper {
  position: relative;
  padding: 1vmin 3vmin;
  margin-bottom: 10px;

  border: 2px solid #972bc9;
  background-color: rgba(#f8f083, 0.5);
  border-radius: 1vmin;

  @media only screen and (min-width: 768px) {
    padding-left: 5vmin;
    padding-right: 5vmin;
    margin-bottom: 2vmin;
  }
}

.prevArrow {
  left: -4.5vmin;
}

.nextArrow {
  right: -4.5vmin;
}

.hidden {
  display: none;
}

.prevArrow,
.nextArrow {
  position: absolute;
  width: 3.5vmin;
  transform: translateY(-50%);
  top: 50%;
  opacity: 0.8;
  transition: opacity 0.2s ease-in;

  &:hover {
    opacity: 1;
  }
}
