.wrapper {
  position: absolute;
  right: 0;
  top: 50%;
  // top: calc(50% - 3.3vmin);
  bottom: 0;
  left: 50%;
  // transform: translateX(-50%);
}

.pagesListInner {
  padding: 0;
}

.currentImage {
  margin-top: 20px;
  width: 100%;
  overflow: hidden;
  text-align: center;

  img {
    max-height: 100%;
    min-height: 180px;
    width: auto;
    max-width: 100%;
  }

  &.big {
    margin-top: 0;
    height: 100%;
    text-align: center;

    img {
      display: inline-block;
      min-height: 290px;
    }
  }
}

.editorWrapper {
  position: absolute;
  // width: 100%;
  left: 0;
  top: 0;

  &.editorEbookMode {
    max-height: 100%;

    .editorInner {
      // flex-direction: column;
      display: flex;
      flex-direction: column;
      overflow: hidden;
    }
  }
}

.editorInner {
  overflow: hidden;

  display: flex;
  flex-direction: column;
}

.navBtn {
  position: absolute;
  top: 0;
  bottom: 0;
  width: calc(50% + 35px);
  display: flex;
  align-items: center;
  user-select: none;
  z-index: 10;

  &:hover img {
    transform: scale(0.95);
  }

  &:active img {
    transform: scale(0.85);
  }

  &.prevBtn {
    justify-content: flex-start;
    left: -35px;
  }

  &.nextBtn {
    justify-content: flex-end;
    right: -35px;
  }

  img {
    width: 5vmin;
    transition: all 0.2s ease;
  }
}

.recorder {
  margin-left: 15px;
  width: 10vmin;
}

.recorderInner {
  flex-direction: column;
}

.recorderButton {
  height: 33%;
}

.bottomSectionWrapper {
  position: absolute;
  left: 50%;
  transform: translateX(-50%);

  display: flex;
  justify-content: center;
}

.approvalCheckbox {
  position: absolute;
  left: -18%;
  top: 30px;
  text-align: center;

  label {
    font-size: 22px !important;
    cursor: pointer;
    position: relative;
    user-select: none;
    display: block;

    &::after {
      content: '';
      position: absolute;
      left: 50%;
      transform: translate(-50%, 35px);
      border: 1px solid #000;
      border-radius: 2px;
      height: 25px;
      width: 25px;
    }
  }

  input {
    display: none;
  }

  input:checked + label {
    color: greenyellow;
  }

  input:checked + label::before {
    content: '✓';
    position: absolute;
    left: 50%;
    transform: translate(-50%, 26px);
    font-size: 40px;
    color: greenyellow;
    z-index: 1;
  }

  @media (max-width: 767px) {

  }
}
